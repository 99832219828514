export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "account_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account management"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
  "picturesManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pictures management"])},
  "galleryNoDataText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data to display"])},
  "selectBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a file"])},
  "labelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or Drop a file here"])},
  "readyToUploadMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to Upload"])},
  "uploadedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded"])},
  "uploadAbortedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload cancelled"])},
  "uploadFailedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload failed"])},
  "alreadyUploadedErrorStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "alreadyUploadedErrorEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["already uploaded"])},
  "wrongImageTypeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong file format. only files accepted are in .jpg, .jpeg or .png format"])},
  "subscribed_registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed Registry"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "newF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed"])},
  "subscribeds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed"])},
  "subscribed_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed Name"])},
  "subscribed_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed Info"])},
  "further_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further Info"])},
  "registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registry"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Id"])},
  "id_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id User"])},
  "tenant_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant Id"])},
  "business_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Name"])},
  "fiscal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiscal Code"])},
  "vat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT Number"])},
  "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "countryside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countryside"])},
  "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
  "extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
  "created_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created on"])},
  "update_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated on"])},
  "deleted_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted on"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])},
  "date_of_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Date"])},
  "expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration Date"])},
  "annotations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annotations"])},
  "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card"])},
  "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards"])},
  "card_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Code"])},
  "cards_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Store"])},
  "card_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Data"])},
  "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
  "memberships_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memberships Store"])},
  "memberships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memberships"])},
  "membership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership"])},
  "membership_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership Types"])},
  "automatic_renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic Renewal"])},
  "membership_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership Data"])},
  "membership_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership Date"])},
  "membership_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership Expiration Date"])},
  "price_lists_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Lists' Store"])},
  "price_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price List"])},
  "price_lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Lists"])},
  "price_list_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price List's Name"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
  "calculation_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation Type"])},
  "expiry_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiry Date"])},
  "medical_certificate_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Certificate's Store"])},
  "medical_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Certificate"])},
  "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
  "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates"])},
  "medical_certificate_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Certificate Data"])},
  "medical_certificate_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Certificate Date"])},
  "medical_certificate_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Certificate Expiration Date"])},
  "certificate_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate's Number"])},
  "insurances_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance's Store"])},
  "insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance"])},
  "insurances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurances"])},
  "insurance_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Data"])},
  "insurance_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Date"])},
  "insurance_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Expiration Date"])},
  "policy_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy's Number"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User info"])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
  "lastame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["role"])},
  "role_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role info"])},
  "role_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role name"])},
  "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
  "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permission"])},
  "required_error_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "required_error_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is required"])},
  "password_length_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 6 characters long"])},
  "password_match_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
  "role_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User must have at least one role"])},
  "requiredErrorStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "requiredErrorEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is required"])},
  "passwordLengthError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 6 characters long"])},
  "passwordMatchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
  "roleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User must have at least one role"])},
  "invalidDateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date must have a valid value expressed in the format dd/MM/yyyy"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "dialogDeleteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WARNING"])},
  "dialogDeleteBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this item?"])},
  "dialog_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATTENZIONE"])},
  "dialog_delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler cancellare questo elemento?"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
  "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "viewT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "generatePdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Pdf"])},
  "createT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "duplicateT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
  "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplication"])},
  "deleteT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removal"])},
  "successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["successful"])},
  "select_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a"])},
  "associateCardT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associate card"])},
  "test_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teast Data"])},
  "footer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All trademarks or registered trademarks are property of their respective owners."])},
  "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved."])}
}