export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "account_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione account"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruoli"])},
  "picturesManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione immagini"])},
  "galleryNoDataText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna immagine da mostrare"])},
  "selectBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un file"])},
  "labelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o Trascina un file qui"])},
  "readyToUploadMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronto per l'Upload"])},
  "uploadedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload riuscito"])},
  "uploadAbortedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload annullato"])},
  "uploadFailedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload fallito"])},
  "alreadyUploadedErrorStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagine"])},
  "alreadyUploadedErrorEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["già caricata"])},
  "wrongImageTypeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato file errato. Sono consentiti solo files in formato .jpg, .jpeg o .png"])},
  "subscribed_registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anagrafica Iscritti"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
  "newF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova"])},
  "subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscritto"])},
  "subscribeds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscritti"])},
  "subscribed_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Iscritto"])},
  "subscribed_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni Iscritto"])},
  "further_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori Informazioni"])},
  "registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anagrafica"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Id"])},
  "id_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id User"])},
  "tenant_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant Id"])},
  "business_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ragione Sociale"])},
  "fiscal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice fiscale"])},
  "vat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partita IVA"])},
  "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualifica"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Località"])},
  "countryside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
  "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAP"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazione"])},
  "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
  "extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
  "created_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creato il"])},
  "update_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiornato il"])},
  "deleted_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminato il"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabilitato"])},
  "date_of_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di registrazione"])},
  "expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di scadenza"])},
  "annotations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annotazioni"])},
  "medical_certificate_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivio Certificati Medici"])},
  "medical_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificato Medico"])},
  "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificato medico"])},
  "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificati medici"])},
  "medical_certificate_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati del Certificato Medico"])},
  "medical_certificate_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Certificato Medico"])},
  "medical_certificate_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Scadenza Certificato Medico"])},
  "certificate_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero Certificato medico"])},
  "insurances_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivio Assicurazioni"])},
  "insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazione"])},
  "insurances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazioni"])},
  "insurance_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati Assicurazione"])},
  "insurance_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Assicurazione"])},
  "insurance_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Scadenza Assicurazione"])},
  "policy_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero Polizza"])},
  "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tessera"])},
  "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tessere"])},
  "card_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Tessera"])},
  "cards_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivio Tessere"])},
  "card_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati Tessera"])},
  "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cellulare"])},
  "memberships_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivio Abbonamenti"])},
  "memberships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamenti"])},
  "membership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento"])},
  "membership_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipologia di Abbonamento"])},
  "automatic_renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinnovo Automatico"])},
  "membership_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati Abbonamento"])},
  "membership_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data inizio"])},
  "membership_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di scadenza"])},
  "price_lists_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivio Listini"])},
  "price_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listino"])},
  "price_lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listini"])},
  "price_list_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Listino"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata"])},
  "calculation_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipologia di Calcolo"])},
  "expiry_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di validità"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente"])},
  "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni utente"])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma password"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ruolo"])},
  "role_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni ruolo"])},
  "role_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome ruolo"])},
  "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permessi"])},
  "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permesso"])},
  "required_error_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo"])},
  "required_error_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obbligatorio"])},
  "password_length_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password deve essere composta da almeno 6 caratteri"])},
  "password_match_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le password non coincidono"])},
  "role_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utente deve possedere almeno un ruolo"])},
  "requiredErrorStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo"])},
  "requiredErrorEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obbligatorio"])},
  "passwordLengthError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password deve essere composta da almeno 6 caratteri"])},
  "passwordMatchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le password non coincidono"])},
  "roleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utente deve possedere almeno un ruolo"])},
  "invalidDateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data deve avere un valore valido espresso in formato gg/mm/aaaa"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
  "dialogDeleteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATTENZIONE"])},
  "dialogDeleteBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler cancellare questo elemento?"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento..."])},
  "dialog_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATTENZIONE"])},
  "dialog_delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler cancellare questo elemento?"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricarica"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi"])},
  "viewT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizzazione"])},
  "generatePdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera Pdf"])},
  "createT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
  "duplicateT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplica"])},
  "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicazione"])},
  "deleteT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
  "associateCardT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associa tessera"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimozione"])},
  "successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["riuscita"])},
  "select_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un"])},
  "test_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teast Data"])},
  "footer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i marchi o i marchi registrati sono proprietà dei rispettivi proprietari."])},
  "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i diritti riservati."])}
}