import { defineStore } from "pinia";
import GalleryService from "@/services/galleryServices/GalleryService";

export const useGalleryStore = defineStore("GalleryStore", {
  state: () => ({
    gallerySrc: [],
    galleryReadonly: false,
  }),

  actions: {
    getGallery(tableName, tableId) {
      return GalleryService.getGallery(tableName, tableId).then(
        (gallery) => {
          this.gallerySrc = gallery.data;
          return Promise.resolve({
            data: this.gallerySrc,
            totalCount: this.gallerySrc.length,
          });
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createGallery(item) {
      return GalleryService.createGallery(item).then(
        (create) => {
          return Promise.resolve(create);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateGallery(id, item) {
      return GalleryService.updateGallery(id, item).then(
        (update) => {
          return Promise.resolve(update);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteGallery(id) {
      return GalleryService.deleteGallery(id).then(
        (del) => {
          return Promise.resolve(del);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
});
