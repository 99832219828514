import axiosInstance from "../axiosSetup/api";

const API_URL = "/anagraficaIscritti/";

class IscrittiService {
  randomDataIscritti() {
    return axiosInstance.get(API_URL + "randomDataIscritti");
  }
  getIscrittiSenzaTessera() {
    return axiosInstance.get(API_URL + "iscrittiSenzaTessera");
  }
  getIscrittiSenzaAbbonamento() {
    return axiosInstance.get(API_URL + "iscrittiSenzaAbbonamento");
  }
  getIscrittiSenzaCertificatoMedico() {
    return axiosInstance.get(API_URL + "iscrittiSenzaCertificatoMedico");
  }
  getIscrittiSenzaAssicurazione() {
    return axiosInstance.get(API_URL + "iscrittiSenzaAssicurazione");
  }
  getAllIscritti() {
    return axiosInstance.get(API_URL);
  }
  createIscritti(item) {
    return axiosInstance.post(API_URL, item);
  }
  updateIscritti(item) {
    return axiosInstance.put(API_URL + item.id, item);
  }
  deleteIscritti(item) {
    return axiosInstance.delete(API_URL + item.id);
  }
}
export default new IscrittiService();
