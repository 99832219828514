export default [
  {
    localeCode: "home",
    text: "Home",
    path: "/home",
    icon: "home",
  },
  {
    localeCode: "account_management",
    text: "Gestione account",
    icon: "user",
    items: [
      {
        localeCode: "profile",
        text: "Profilo",
        path: "/profile",
      },
      {
        localeCode: "users",
        text: "Utenti",
        path: "/users",
        // icon: 'user'
      },
      {
        localeCode: "roles",
        text: "Ruoli",
        path: "/roles",
        // icon: 'user'
      },
    ],
  },
  {
    localeCode: "subscribed_registry",
    text: "Anagrafica Iscritti",
    path: "/anagraficaIscritti",
    icon: "folder",
  },
  {
    localeCode: "cards_store",
    text: "Archivio Tessere",
    path: "/archivioTessere",
    icon: "card",
  },
  {
    localeCode: "memberships_store",
    text: "Archivio Abbonamenti",
    path: "/archivioAbbonamenti",
    icon: "runner",
  },
  {
    localeCode: "price_lists_store",
    text: "Archivio Listini",
    path: "/archivioListini",
    icon: "cart",
  },
  {
    localeCode: "medical_certificate_store",
    text: "Archivio Certificati Medici",
    path: "/archivioCertificatiMedici",
    icon: "paste",
  },
  // {
  //   localeCode: "insurances_store",
  //   text: "Archivio Assicurazioni",
  //   path: "/archivioAssicurazioni",
  //   icon: "product",
  // },
];
