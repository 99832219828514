import { defineStore } from "pinia";
import TessereService from "@/services/tessereServices/TessereService";

export const useTessereStore = defineStore("ArchivioTessereStore", {
  state: () => ({
    tessere: [],
  }),

  actions: {
    getAllTessere() {
      return TessereService.getAllTessere().then(
        (tessera) => {
          //console.log("tessere----", tessere);
          this.tessere = tessera.data;
          return Promise.resolve({
            data: this.tessere,
            totalCount: this.tessere.length,
          });
        },
        (error) => {
          // const message =
          //     (error.response &&
          //         error.response.data &&
          //         error.response.data.message) ||
          //     error.message ||
          //     error.toString();
          return Promise.reject(error);
        }
      );
    },
    createTessere(item) {
      return TessereService.createTessere(item).then(
        (create) => {
          return Promise.resolve(create);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateTessere(item) {
      return TessereService.updateTessere(item).then(
        (update) => {
          return Promise.resolve(update);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteTessere(item) {
      return TessereService.deleteTessere(item).then(
        (del) => {
          return Promise.resolve(del);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    associateTesseraWrite(item) {
      return TessereService.associateTesseraWrite(item).then(
        (create) => {
          return Promise.resolve(create);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    associateTesseraErase() {
      return TessereService.associateTesseraErase().then(
        (create) => {
          return Promise.resolve(create);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    associateTesseraRead() {
      return TessereService.associateTesseraRead().then(
        (create) => {
          return Promise.resolve(create);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
});
