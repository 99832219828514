import axiosInstance from "../axiosSetup/api";

const API_URL = "/archiveDocument/";

class GalleryService {
    getGallery(tableName, tableId) {
        return axiosInstance.get(API_URL + tableName + '/' + tableId);
    }
    createGallery(item) {
        return axiosInstance.post(API_URL, item);
    }
    updateGallery(id, item) {
        return axiosInstance.put(API_URL + id, item);
    }
    deleteGallery(id) {
        return axiosInstance.delete(API_URL + id);
    }
}

export default new GalleryService();