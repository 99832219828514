import axiosInstance from "../axiosSetup/api";
import axiosUfrInstance from "../axiosSetup/ufrApiServer";


const API_URL = "/archivioTessere/";

class TessereService {
  getAllTessere() {
    return axiosInstance.get(API_URL);
  }
  createTessere(item) {
    return axiosInstance.post(API_URL, item);
  }
  updateTessere(item) {
    return axiosInstance.put(API_URL + item.id, item);
  }
  deleteTessere(item) {
    return axiosInstance.delete(API_URL + item.id);
  }
  associateTesseraErase(){
    return axiosUfrInstance.get("/erase");
  }
  associateTesseraRead(){
    return axiosUfrInstance.get("/read");
  }
  associateTesseraWrite(item){
    return axiosUfrInstance.get("/write/"+item);
  }
}

export default new TessereService();
