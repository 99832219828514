import { defineStore } from "pinia";
import AbbonamentiService from "@/services/abbonamentiServices/AbbonamentiService";

export const useAbbonamentiStore = defineStore("ArchivioAbbonamentiStore", {
  state: () => ({
    abbonamenti: [],
  }),

  actions: {
    getAllAbbonamenti() {
      return AbbonamentiService.getAllAbbonamenti().then(
        (abbonamenti) => {
          //console.log("abbonamenti----", abbonamenti);
          this.abbonamenti = abbonamenti.data;
          return Promise.resolve({
            data: this.abbonamenti,
            totalCount: this.abbonamenti.length,
          });
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createAbbonamenti(item) {
      return AbbonamentiService.createAbbonamenti(item).then(
        (create) => {
          return Promise.resolve(create);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateAbbonamenti(item) {
      return AbbonamentiService.updateAbbonamenti(item).then(
        (update) => {
          return Promise.resolve(update);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteAbbonamenti(item) {
      return AbbonamentiService.deleteAbbonamenti(item).then(
        (del) => {
          return Promise.resolve(del);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
});
