import axiosR from "./api";
import { useAuthStore } from "@/stores/AuthStore";
// import store from "@/store/index";

/*
 * funzione che estende l'istanza di axios 'axiosR' con funzionalit\a di refresh del token;
 */
const setup = async () => {
  // let user = JSON.parse(localStorage.getItem("user"));
  // if (user != null) {
  //   // await store.dispatch("Auth/refreshToken")
  //   try {
  //     let locUser = null;
  //     await axiosR
  //       .post("/accounts/refresh-token", {}, { withCredentials: true })
  //       .then((response) => (locUser = response.data));
  //     localStorage.setItem("user", JSON.stringify(locUser));
  //   } catch (_error) {
  //     console.log(_error)
  //     const auth = useAuthStore();
  //     await auth.logOut();
  //     return Promise.reject(_error);
  //   }
  // }
  axiosR.interceptors.request.use(
    (config) => {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = user?.jwtToken;
      if (token) {
        config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
        //config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosR.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      try {
        console.log("error:::", err);
        if (err.code == "ERR_NETWORK") {
          if (err.config.url != "http://127.0.0.1:30148/api/v1") {
            console.log(err)
            const auth = useAuthStore();
            await auth.logOut();
            return Promise.reject(err);
          }
        }
        const originalConfig = err.config;
        if (
          originalConfig.url !== "/accounts/authenticate" &&
          originalConfig.url !== "/accounts/register" &&
          originalConfig.url !== "/accounts/forgot-password" &&
          originalConfig.url !== "/accounts/reset-password" &&
          err.response
        ) {
          // Access Token was expired
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            let user = null;
            try {
              await axiosR
                .post("/accounts/refresh-token", {}, { withCredentials: true })
                .then((response) => (user = response.data));
              localStorage.setItem("user", JSON.stringify(user));
              // await store.dispatch("Auth/refreshToken");
              return axiosR(originalConfig);
            } catch (_error) {
              const auth = useAuthStore();
              await auth.logOut();
              return Promise.reject(_error);
            }
          }
        }
      }
      catch (_error) {
        console.log(_error)
        const auth = useAuthStore();
        await auth.logOut();
        return Promise.reject(_error);
      }
      throw err;
    }
  );
};

export default setup;
