import axiosInstance from "../axiosSetup/api";

const API_URL = "/role/";

class RolesService {
    getRoles() {
        return axiosInstance.get(API_URL);
    }
    getPermissions() {
        return axiosInstance.get(API_URL + 'get-permissions/');
    }
    createRole(item) {
        return axiosInstance.post(API_URL, item);
    }
    updateRole(item) {
        return axiosInstance.put(API_URL + item.id, item);
    }
    deleteRole(item) {
        return axiosInstance.delete(API_URL + item.id);
    }
}

export default new RolesService();