import { useAuthStore } from '@/stores/AuthStore';
import { createRouter, createWebHashHistory } from "vue-router";

import Home from "./views/home-page";
import Profile from "./views/profile-page";
import Users from "./views/users/users-page";
import Roles from "./views/roles/roles-page";
import AnagraficaIscritti from "./views/anagraficaIscritti/anagrafica-iscritti-page.vue";
import ArchivioTessere from "./views/archivioTessere/archivio-tessere-page.vue";
import ArchivioAbbonamenti from "./views/archivioAbbonamenti/archivio-abbonamenti-page.vue";
import ArchivioListini from "./views/archivioListini/archivio-listini-page.vue";
import ArchivioCertificatiMedici from "./views/archivioCertificatiMedici/archivio-certificati-medici-page.vue";
// import ArchivioAssicurazioni from "./views/archivioAssicurazioni/archivio-assicurazioni-page.vue";

import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";

function loadView(view) {
  return () => import(/* webpackChunkName: "login" */ `./views/${view}.vue`);
}

const router = new createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Home,
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Profile,
    },
    {
      path: "/users",
      name: "users",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Users,
    },
    {
      path: "/roles",
      name: "roles",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Roles,
    },
    {
      path: "/anagraficaIscritti",
      name: "anagraficaIscritti",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: AnagraficaIscritti,
    },
    {
      path: "/archivioTessere",
      name: "archivioTessere",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ArchivioTessere,
    },

    {
      path: "/archivioAbbonamenti",
      name: "archivioAbbonamenti",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ArchivioAbbonamenti,
    },
    {
      path: "/archivioListini",
      name: "archivioListini",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ArchivioListini,
    },
    {
      path: "/archivioCertificatiMedici",
      name: "archivioCertificatiMedici",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ArchivioCertificatiMedici,
    },
    // {
    //   path: "/archivioAssicurazioni",
    //   name: "archivioAssicurazioni",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout,
    //   },
    //   component: ArchivioAssicurazioni,
    // },

    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Assistant Gimmy - Login",
      },
      component: loadView("login-form"),
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description:
          "Please enter the email address that you used to register, and we will send you a link to reset your password via Email.",
      },
      component: loadView("reset-password-form"),
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up",
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password",
      },
      component: loadView("change-password-form"),
    },
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/recovery",
      redirect: "/home",
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home",
    },
  ],
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const auth = useAuthStore();
  if (to.name === "login-form" && auth.loggedIn) {
    next({ name: "home" });
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!auth.loggedIn) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
