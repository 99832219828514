import axiosInstance from "../axiosSetup/api";

const API_URL = "/accounts/";

class UsersService {
    getById(item) {
        return axiosInstance.get(API_URL + item.id);
    }
    getAccounts() {
        return axiosInstance.get(API_URL);
    }
    createAccount(item) {
        return axiosInstance.post(API_URL, item);
    }
    updateAccount(item) {
        return axiosInstance.put(API_URL + item.id, item);
    }
    deleteAccount(item) {
        return axiosInstance.delete(API_URL + item.id);
    }
    getPdf(item) {
        return axiosInstance.get(API_URL + 'get-pdf/' + item.id, { responseType: 'blob' });
    }
}

export default new UsersService();
