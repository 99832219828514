import { defineStore } from "pinia";
import RolesService from "@/services/rolesServices/RolesServices";

export const useRolesStore = defineStore("RolesStore", {
  state: () => ({
    roles: [],
    permissions: [],
  }),

  actions: {
    getRoles() {
      return RolesService.getRoles().then(
        (ruoli) => {
          //console.log("ruoli::::", ruoli);
          this.roles = ruoli.data;
          return Promise.resolve(this.roles);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getPermissions() {
      return RolesService.getPermissions().then(
        (permessi) => {
          this.permissions = permessi.data;
          return Promise.resolve(this.permissions);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createRole(item) {
      return RolesService.createRole(item).then(
        (update) => {
          return Promise.resolve(update);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateRole(item) {
      return RolesService.updateRole(item).then(
        (update) => {
          return Promise.resolve(update);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteRole(item) {
      return RolesService.deleteRole(item).then(
        (del) => {
          return Promise.resolve(del);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },

  getters: {},
});
