import { defineStore } from "pinia";
import UsersService from "@/services/accountServices/UsersService"

export const useUsersStore = defineStore("UsersStore", {
    state: () => ({
        users: [],
        user: null,
    }),

    actions: {
        getUser(item) {
            return UsersService.getById(item).then(
                (utente) => {
                    this.user = utente.data;
                    return Promise.resolve({ data: this.user, totalCount: this.user.length });
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },
        getUsers() {
            return UsersService.getAccounts().then(
                (utenti) => {
                    this.users = utenti.data;
                    return Promise.resolve({ data: this.users, totalCount: this.users.length });
                },
                (error) => {
                    // const message =
                    //     (error.response &&
                    //         error.response.data &&
                    //         error.response.data.message) ||
                    //     error.message ||
                    //     error.toString();
                    return Promise.reject(error);
                }
            );
        },
        createUser(item) {
            return UsersService.createAccount(item).then(
                (create) => {
                    return Promise.resolve(create);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },
        updateUser(item) {
            return UsersService.updateAccount(item).then(
                (update) => {
                    return Promise.resolve(update);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },
        deleteUser(item) {
            return UsersService.deleteAccount(item).then(
                (del) => {
                    return Promise.resolve(del);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },
        getPdf(item) {
            return UsersService.getPdf(item).then(
                (pdf) => {
                    return Promise.resolve(pdf);
                },
                (error) => {
                    return Promise.reject(error);
                }
            )
        }
    },

    getters: {
        //notMenuOpened: (state) => !state.menuOpened,
    },
});
