export default {
  title: "Assistant Gimmy",
};

export const settings = {
  siteName: "AssistantEvolution",
  apiUrl: "https://testarea-gimmy-backend.qcsnet.it",
  wsUrl: "https://testarea-gimmy-backend.qcsnet.it",
  uFRServerUrl: "http://192.168.10.65:5968",
  guestRoutes: ["login", "register", "passwordrecovery"],
};

