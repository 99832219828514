<template>
  <div id="container">
    <div class="content-block borderingOrange">
      <DxDataGrid id="dataGrid" ref="gridRef" :data-source="tessereData" :show-borders="true">
        <DxSearchPanelGrid :visible="true" />
        <DxHeaderFilterGrid :visible="true" />
        <DxFilterRowGrid :visible="true" />
        <DxPagingGrid :enabled="false" />
        <DxScrollingGrid mode="virtual" />
        <DxEditingGrid :allow-adding="canRead" :allow-updating="canUpdate" :allow-deleting="canDelete"
          :allow-filtering="true" :use-icons="true" refresh-mode="refresh" mode="popup">
        </DxEditingGrid>
        <DxColumnGrid data-type="string" data-field="nome_iscritto" :caption="$t('subscribed_name')"
          :allow-header-filtering="false" :visible="true" />
        <DxColumnGrid data-type="string" data-field="codice_tessera" :caption="$t('card_code')"
          :allow-header-filtering="false" :visible="true" />
        <DxColumnGrid data-field="creato_il" :caption="$t('created_on')" data-type="date" format="dd/MM/yyyy"
          :allow-header-filtering="false" :visible="true" />
        <DxColumnGrid cell-template="cellTemplate" data-field="activeValue" :caption="$t('status') + ' ' + $t('card')"
          data-type="boolean" trueText="Attivo" falseText="Disabilitato" :allow-header-filtering="false" :visible="true"
          :filter-value="true">
        </DxColumnGrid>
        <template #cellTemplate="{ data }">
          <DxCheckBox :value="data.value" :readOnly="true" />
        </template>
        <DxColumnGrid type="buttons">
          <DxButtonGrid name="read" v-if="canRead" icon="isblank" :hint="$t('viewT')" @click="readItem($event)" />
          <DxButtonGrid name="edit" v-if="canUpdate" :hint="$t('update')" @click="updateItem($event)" />
          <DxButtonGrid name="duplicate" v-if="canDuplicate" icon="copy" :hint="$t('duplicateT')"
            @click="duplicateItem($event)" />
          <DxButtonGrid name="associateCard" v-if="canUpdate" icon="card" :hint="$t('associateCardT')"
            @click="associateCard($event)" />
          <DxButtonGrid name="delete" v-if="canDelete" :hint="$t('deleteT')" @click="deleteItem($event)" />
        </DxColumnGrid>
        <DxToolbarGrid>
          <DxItemGrid location="before" template="front" />
          <DxItemGrid location="after" template="addBtn" />
          <DxItemGrid location="after" template="refreshBtn" />
        </DxToolbarGrid>
        <template #front>
          <span class="grid-front colorOrange">{{ $t("cards_store") }}</span>
        </template>
        <template #addBtn>
          <DxButton name="add" v-if="canCreate" type="contained" icon="add" :hint="$t('add')" @click="createItem()">
          </DxButton>
        </template>
        <template #refreshBtn>
          <DxButton name="refresh" type="contained" icon="refresh" :hint="$t('refresh')" @click="refresh()">
          </DxButton>
        </template>
      </DxDataGrid>
      <DxPopup :visible="popup" :title="popupTitle" content-template="content" :show-title="true" :full-screen="true"
        :drag-enabled="false" :hide-on-outiside-click="false" :show-close-button="false" @shown="onPopupShown">
        <template #content>
          <DxScrollView>
            <DxForm :form-data="editedItem" label-mode="floating" class="card" ref="formRef" :col-count="2">
              <DxItemForm item-type="group" :caption="$t('card_data')">
                <DxItemForm :visible="actionType == 'update' || actionType == 'read'" data-field="nome_iscritto"
                  editor-type="dxTextBox" :label="{ text: $t('subscribed_name') }" :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: true,
                  }" />
                <DxItemForm :visible="actionType != 'update' && actionType != 'read'" data-field="user_id"
                  editor-type="dxSelectBox" :label="{ text: $t('subscribed_name') }" :editor-options="{
                    dataSource: iscrittiData,
                    displayExpr: 'nome_intero',
                    valueExpr: 'id',
                    placeholder: $t('select_place_holder') + ' ' + $t('subscribed_name') + '...',
                    searchEnabled: true,
                    readOnly: readonly,
                  }">
                  <DxRequiredRule
                    :message="$t('required_error_start') + ' ' + $t('subscribeds') + ' ' + $t('required_error_end')" />
                </DxItemForm>
                <DxItemForm>
                  <template #default>
                    <DxTextBox v-model:value="editedItem.codice_tessera" styling-mode="outlined" :read-only="true"
                      :placeholder="$t('card_code')">
                      <DxButtonTextBox name="associateCard" :options="associateButton" />
                    </DxTextBox>
                  </template>
                </DxItemForm>
              </DxItemForm>
              <DxItemForm item-type="group" :caption="$t('further_info')">
                <DxItemForm data-field="creato_il" editor-type="dxDateBox" :label="{ text: $t('created_on'), }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: true,
                    maskBehavior: 'true',
                    displayFormat: 'dd/MM/yyyy HH:mm:ss',
                  }">
                </DxItemForm>
                <DxItemForm data-field="aggiornato_il" editor-type="dxDateBox" :label="{ text: $t('update_on'), }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: true,
                    maskBehavior: 'true',
                    displayFormat: 'dd/MM/yyyy HH:mm:ss',
                  }">
                </DxItemForm>

                <DxItemForm data-field="eliminato_il" editor-type="dxDateBox" :label="{ text: $t('deleted_on'), }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: true,
                    maskBehavior: 'true',
                    displayFormat: 'dd/MM/yyyy HH:mm:ss',
                  }">
                </DxItemForm>
                <DxItemForm data-field="activeValue" editor-type="dxCheckBox" :label="{ text: $t('active'), }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }">
                </DxItemForm>
              </DxItemForm>
            </DxForm>
          </DxScrollView>
        </template>
        <DxToolbarItemPopup v-if="!readonly" widget="dxButton" location="after" toolbar="bottom" :options="{
          text: $t('save'),
          type: 'default',
          stylingMode: 'outlined',
          onClick: save,
        }" />
        <DxToolbarItemPopup widget="dxButton" location="after" toolbar="bottom" :options="{
          text: readonly ? $t('close') : $t('cancel'),
          type: 'default',
          stylingMode: 'outlined',
          onClick: closePopup,
        }" />
      </DxPopup>
      <DxPopup v-model:visible="popupDelete" max-width="500px" max-height="160px" title-template="front"
        content-template="body" :drag-enabled="false" :hide-on-outiside-click="false">
        <template #front>
          <div id="delDiv">
            <span id="delTitle">
              {{ $t("dialog_delete_title") }}
            </span>
          </div>
        </template>
        <template #body>
          <div id="delDiv">
            <span id="delBody">{{ $t("dialog_delete_body") }}</span>
          </div>
        </template>
        <DxToolbarItemPopup widget="dxButton" location="center" toolbar="bottom" :options="{
          text: $t('yes'),
          type: 'default',
          stylingMode: 'contained',
          onClick: deleteItemConfirm,
        }" />
        <DxToolbarItemPopup widget="dxButton" location="center" toolbar="bottom" :options="{
          text: $t('no'),
          type: 'default',
          stylingMode: 'contained',
          onClick: closeDelete,
        }" />
      </DxPopup>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import CustomStore from "devextreme/data/custom_store";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";

import DataSource from "devextreme/data/data_source";

import notify from "devextreme/ui/notify";
import "devextreme-vue/tag-box";

import DxDataGrid, {
  DxColumn as DxColumnGrid,
  DxSearchPanel as DxSearchPanelGrid,
  DxHeaderFilter as DxHeaderFilterGrid,
  DxFilterRow as DxFilterRowGrid,
  DxPaging as DxPagingGrid,
  DxEditing as DxEditingGrid,
  DxButton as DxButtonGrid,
  DxItem as DxItemGrid,
  DxToolbar as DxToolbarGrid,
  DxScrolling as DxScrollingGrid,
} from "devextreme-vue/data-grid";

import DxForm, {
  DxItem as DxItemForm,
  DxRequiredRule,
} from "devextreme-vue/form";
import { DxCheckBox } from "devextreme-vue/check-box";
import DxScrollView from "devextreme-vue/scroll-view";
import DxButton from "devextreme-vue/button";
import DxPopup, {
  DxToolbarItem as DxToolbarItemPopup,
} from "devextreme-vue/popup";
import DxTextBox, {
  DxButton as DxButtonTextBox,
} from "devextreme-vue/text-box";

import { useI18n } from "vue-i18n";
import { useTessereStore } from "@/stores/ArchivioTessereStore";
import { useIscrittisStore } from "@/stores/AnagraficaIscrittiStore";
import { settings } from "@/app-info";
import Guid from "devextreme/core/guid";

export default {
  setup() {
    const { t } = useI18n();
    const WS_URL = settings.wsUrl;

    const archivioTessereStore = useTessereStore();
    const anagraficaIscrittiStore = useIscrittisStore();

    // const dataIscrizione = new Date();

    // const dataScadenza = new Date(
    //   dataIscrizione.getFullYear() + 1,
    //   dataIscrizione.getMonth(),
    //   dataIscrizione.getDate()
    // );
    //const dataScadenza = data_iscrizione.value.getFullYear();

    const defaultItem = ref({
      id: "",
      tenant_id: "",
      user_id: "",
      // data_iscrizione: dataIscrizione,
      // data_scadenza: dataScadenza,
      codice_tessera: "",
      nome_iscritto: "",
      creato_il: null,
      aggiornato_il: null,
      eliminato_il: null,
      //disabilitato: false,
      activeValue: true,
      tenant: null,
    });
    const editedItem = ref({
      id: "",
      tenant_id: "",
      user_id: "",
      // data_iscrizione: dataIscrizione,
      // data_scadenza: dataScadenza,
      codice_tessera: "",
      nome_iscritto: "",
      creato_il: null,
      aggiornato_il: null,
      eliminato_il: null,
      //disabilitato: false,
      activeValue: true,
      tenant: null,
    });
    const canCreate = ref(false);
    const canRead = ref(false);
    const canUpdate = ref(false);
    const canDuplicate = ref(false);
    const canDelete = ref(false);

    const gridRef = ref(null);
    const formRef = ref(null);
    const popup = ref(false);
    const popupDelete = ref(false);
    const popupTitle = ref("");
    const formData = ref(null);
    const readonly = ref(false);
    const index = ref(-1);
    const connectionStarted = ref(false);
    const actionType = ref("");

    const { tessere } = storeToRefs(archivioTessereStore);

    const store = new CustomStore({
      load: () => archivioTessereStore.getAllTessere(),
      key: "id",
    });

    const tessereData = ref(
      new DataSource({
        store: store,
        reshapeOnPush: true,
      })
    );

    const { iscritti } = storeToRefs(anagraficaIscrittiStore);

    const storeIscritti = new CustomStore({
      load: () => anagraficaIscrittiStore.getIscrittiSenzaTessera(),
      key: "id",
      loadMode: "raw",
    });

    const iscrittiData = ref(
      new DataSource({
        store: storeIscritti,
        paginate: true,
        pageSize: 10,
        reshapeOnPush: true,
      })
    );

    onMounted(async () => {
      await anagraficaIscrittiStore.getAllIscritti();

      // permessi
      let user = JSON.parse(localStorage.getItem("user"));

      if (user.permissions.includes("Admin")) {
        canCreate.value = true;
        canRead.value = true;
        canUpdate.value = true;
        canDuplicate.value = true;
        canDelete.value = true;
      }
      if (user.permissions.includes("Role")) {
        canCreate.value = true;
        canRead.value = true;
        canUpdate.value = true;
        canDuplicate.value = true;
      }
      if (user.permissions.includes("User")) {
        canRead.value = true;
      }

      connectionStarted.value = true;

      const hubConnection = new HubConnectionBuilder()
        .withUrl(WS_URL + "/archivioTessereHub", {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        })
        .build();

      hubConnection.start().then(() => {
        let userCookie = JSON.parse(localStorage.getItem("user"));
        hubConnection.on("insert", (tenant, name, data) => {
          if (
            (userCookie.tenant === null && name.toUpperCase() == "DEFAULT") ||
            userCookie.tenant.id === tenant
          ) {
            data = JSON.parse(data);
            store.push([{ type: "insert", key: data.id, data }]);
          }
        });
        hubConnection.on("update", (tenant, name, data) => {
          if (
            (userCookie.tenant === null && name.toUpperCase() == "DEFAULT") ||
            userCookie.tenant.id === tenant
          ) {
            data = JSON.parse(data);
            store.push([{ type: "update", key: data.id, data }]);
          }
        });
        hubConnection.on("remove", (tenant, name, id) => {
          if (
            (userCookie.tenant === null && name.toUpperCase() == "DEFAULT") ||
            userCookie.tenant.id === tenant
          ) {
            store.push([{ type: "remove", key: id }]);
          }
        });
      });
    });

    function createItem() {
      popupTitle.value = t("newF") + " " + t("card");
      actionType.value = "create";
      editedItem.value = JSON.parse(JSON.stringify(defaultItem.value));
      let guid = new Guid();
      editedItem.value.codice_tessera = guid._value;
      console.log("editeditem", editedItem.value);
      console.log("guid", guid);
      openPopup();
    }
    function readItem(event) {
      popupTitle.value = t("viewT") + " " + t("card");
      actionType.value = "read";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      index.value = event.row.dataIndex;
      readonly.value = true;
      openPopup();
    }
    function updateItem(event) {
      popupTitle.value = t("update") + " " + t("card");
      actionType.value = "update";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      index.value = event.row.dataIndex;
      openPopup();
    }
    function duplicateItem(event) {
      popupTitle.value = t("duplicate") + " " + t("card");
      actionType.value = "duplicate";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      editedItem.value.id = null;
      editedItem.value.user_id = null;
      editedItem.value.codice_tessera = null;
      editedItem.value.tenant_id = null;
      editedItem.value.nome_iscritto = null;
      editedItem.value.creato_il = null;
      editedItem.value.aggiornato_il = null;
      editedItem.value.eliminato_il = null;
      editedItem.value.activeValue = true;
      openPopup();
    }
    function deleteItem(event) {
      actionType.value = "delete";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      index.value = event.row.dataIndex;
      openDelete();
    }
    async function deleteItemConfirm() {
      await archivioTessereStore.deleteTessere(editedItem.value);
      let item = JSON.parse(JSON.stringify(editedItem.value));
      store.push([{ type: "remove", key: item.id }]);
      showToast("success");
      closeDelete();
    }

    const associateButton = {
      icon: "card",
      type: "default",
      disabled: false,
      onClick: () => {
        associateCard(null, editedItem.value.codice_tessera);
      },
    };

    async function associateCard(event = null, guid = null) {
      let value = null;
      actionType.value = "associate";
      if (event === null) {
        value = { codice_tessera: guid };
      } else {
        value = JSON.parse(JSON.stringify(event.row.data));
        index.value = event.row.dataIndex;
      }
      const erase = await archivioTessereStore.associateTesseraErase();
      const er = JSON.parse(JSON.stringify(erase.data));
      if (er.status !== 200) {
        showToast("error", er.message);
        return false;
      }
      const write = await archivioTessereStore.associateTesseraWrite(
        value.codice_tessera
      );
      const wr = JSON.parse(JSON.stringify(write.data));
      if (wr.status !== 200) {
        showToast("error", wr.message);
        return false;
      }
      const read = await archivioTessereStore.associateTesseraRead();
      const re = JSON.parse(JSON.stringify(read.data));
      if (re.status !== 200) {
        showToast("error", re.message);
        return false;
      }
      console.log(re);
      showToast("success", null, re.message);
    }
    async function save() {
      if (formRef.value.instance.validate().isValid) {
        //console.log(JSON.parse(JSON.stringify(editedItem.value)));
        if (index.value > -1) {
          await archivioTessereStore
            .updateTessere(editedItem.value)
            .then((data) => {
              let item = JSON.parse(JSON.stringify(data.data));
              store.push([{ type: "update", key: item.id, data: item }]);
              showToast("success");
              closePopup();
            })
            .catch((error) => {
              console.log(error);
              let message = error.response.data.title
                ? error.response.data.title
                : error.response.data.message;
              showToast("error", message);
            });
        } else {
          editedItem.value.title = "them";
          await archivioTessereStore
            .createTessere(editedItem.value)
            .then((data) => {
              let item = JSON.parse(JSON.stringify(data.data));
              store.push([{ type: "insert", key: item.id, data: item }]);
              showToast("success");
              closePopup();
            })
            .catch((error) => {
              console.log(error);
              let message = error.response.data.title
                ? error.response.data.title
                : error.response.data.message;
              showToast("error", message);
            });
        }
      }
    }

    function showToast(type, error = null) {
      let message;
      if (type == "success")
        message = t(actionType.value) + " " + t("successful");
      else message = error;
      notify(
        {
          message: message,
          maxWidth: 600,
        },
        type,
        4000
      );
    }

    function refresh() {
      tessereData.value.reload();
      iscrittiData.value.reload();
    }

    function openPopup() {
      popup.value = true;
      iscrittiData.value.reload();
    }

    async function onPopupShown() {
      //pLoadingGrid.value = false;
      formRef.value?.instance?.repaint();
      //setTimeout(() => pLoading.value = false, 100)
      // nextTick(() => {
      // 	nextTick(() => {
      // 		const toFocus = formRef.value?.instance?.getEditor('insegnaId');
      // 		toFocus?.focus();
      // 	})
      // });
    }
    async function closePopup() {
      popup.value = false;
      formRef.value?.instance?.resetValues();
      // index.value = -1;
      // readonly.value = false;
      //editedItem.value = JSON.parse(JSON.stringify(defaultItem.value));
      // actionType.value = "";
    }
    function openDelete() {
      popupDelete.value = true;
    }
    function closeDelete() {
      popupDelete.value = false;
      index.value = -1;
      actionType.value = "";
    }

    function passMatch() {
      return editedItem.value
        ? editedItem.value.password
          ? editedItem.value.password
          : ""
        : "";
    }

    return {
      // VARIABILI

      //users,
      //usersData,
      //roles,
      defaultItem,
      editedItem,
      popup,
      popupDelete,
      popupTitle,
      formData,
      readonly,
      index,
      gridRef,
      formRef,
      connectionStarted,
      actionType,
      tessere,
      tessereData,
      iscritti,
      iscrittiData,

      associateButton,

      // PERMESSI
      canCreate,
      canRead,
      canUpdate,
      canDuplicate,
      canDelete,

      // FUNZIONI
      openPopup,
      onPopupShown,
      closePopup,
      openDelete,
      closeDelete,
      passMatch,
      associateCard,

      // CRUD
      createItem,
      readItem,
      updateItem,
      duplicateItem,
      deleteItem,
      deleteItemConfirm,
      refresh,
      save,
    };
  },
  components: {
    DxDataGrid,
    DxColumnGrid,
    DxSearchPanelGrid,
    DxHeaderFilterGrid,
    DxFilterRowGrid,
    DxPagingGrid,
    DxEditingGrid,
    DxToolbarGrid,
    DxItemGrid,
    DxButtonGrid,
    //DxLookupGrid,
    DxScrollingGrid,
    DxCheckBox,
    DxTextBox,
    DxButtonTextBox,

    DxForm,
    DxItemForm,
    DxRequiredRule,
    //DxStringLengthRule,
    // DxCompareRule,

    DxScrollView,

    DxPopup,
    DxToolbarItemPopup,

    DxButton,
  },
};
</script>

<style scoped>
#dataGrid {
  height: 93vh;
}

#delDiv {
  text-align: center;
}

#delTitle {
  font-size: 30px;
  margin: 300px auto;
}

#delBody {
  font-size: 20px;
}

.card {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 20px;
  margin: 1px;
  border-radius: 5px;
  border: 1px solid #596980;
}

.grid-front {
  font-size: 36px;
}

:deep(.dx-datagrid-header-panel .dx-toolbar) {
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

:deep(.dx-toolbar .dx-toolbar-before) {
  margin-top: -6px;
}

:deep(.dx-header-row) {
  color: #ff5722
}
</style>
