import axiosInstance from "../axiosSetup/api";

const API_URL = "/archivioAbbonamenti/";

class AbbonamentiService {
  getAllAbbonamenti() {
    return axiosInstance.get(API_URL);
  }
  createAbbonamenti(item) {
    return axiosInstance.post(API_URL, item);
  }
  updateAbbonamenti(item) {
    return axiosInstance.put(API_URL + item.id, item);
  }
  deleteAbbonamenti(item) {
    return axiosInstance.delete(API_URL + item.id);
  }
}

export default new AbbonamentiService();
