// import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
// import "devextreme/dist/css/dx.light.css";
// import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
// import "@devexpress/analytics-core/dist/css/dx-analytics.carmine.compact.css";
import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
import "devexpress-dashboard/dist/css/dx-dashboard.light.compact.css";

import { createApp } from "vue";
import router from "./router";

import i18n from "./i18n";
import { createPinia } from "pinia";

import App from "./App";
import appInfo from "./app-info";

import setupInterceptors from "./services/axiosSetup/setupInterceptors.js";

setupInterceptors();

const app = createApp(App).use(i18n);
app.use(router);
app.use(createPinia());
app.config.globalProperties.$appInfo = appInfo;
app.mount("#app");
