import axiosR from "axios";
import { settings } from "@/app-info.js";

const API_URL = settings.apiUrl + "/accounts/";

class AuthService {
  async logIn(email, password) {
    try {
      let url = API_URL;
      let user = null;
      // Send request
      await axiosR
        .post(
          url + "authenticate",
          {
            email,
            password,
          },
          { withCredentials: true }
        )
        .then((response) => {
          user = response.data;
          //console.log(email, password,response);
          localStorage.setItem("user", JSON.stringify(response.data));
        });
      return {
        isOk: true,
        data: user,
      };
    } catch {
      return {
        isOk: false,
        message: "Authentication failed",
      };
    }
  }
  async getUser() {
    try {
      // Send request

      return {
        isOk: true,
        data: this._user,
      };
    } catch {
      return {
        isOk: false,
      };
    }
  }
  async resetPassword(email) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to reset password",
      };
    }
  }
  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to change password",
      };
    }
  }
}

export default new AuthService();
