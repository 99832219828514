import axiosInstance from "../axiosSetup/api";

const API_URL = "/archivioCertificatiMedici/";

class CertificatiService {
  getAllCertificati() {
    return axiosInstance.get(API_URL);
  }
  createCertificati(item) {
    return axiosInstance.post(API_URL, item);
  }
  updateCertificati(item) {
    return axiosInstance.put(API_URL + item.id, item);
  }
  deleteCertificati(item) {
    return axiosInstance.delete(API_URL + item.id);
  }
}

export default new CertificatiService();
