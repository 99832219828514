import { defineStore } from "pinia";
import ListiniService from "@/services/listiniServices/ListiniService";

export const useListinisStore = defineStore("ArchivioListiniStore", {
  state: () => ({
    listiniValidi: [],
    listini: [],
    tipologie: [],
  }),

  actions: {
    getTipologie() {
      return ListiniService.getTipologie().then(
        (tipologia) => {
          this.tipologie = tipologia.data;
          //console.log(this.tipologie);
          return Promise.resolve({
            data: this.tipologie,
            totalCount: this.tipologie.length,
          });
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getListiniValidi() {
      return ListiniService.getListiniValidi().then(
        (listino) => {
          this.listiniValidi = listino.data;
          //console.log("listiniValidi----", listino);
          return Promise.resolve(this.listiniValidi);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getAllListini() {
      return ListiniService.getAllListini().then(
        (listino) => {
          //console.log("listino----", listino);
          this.listini = listino.data;
          return Promise.resolve(this.listini);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createListini(item) {
      return ListiniService.createListini(item).then(
        (create) => {
          return Promise.resolve(create);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateListini(item) {
      return ListiniService.updateListini(item).then(
        (update) => {
          return Promise.resolve(update);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteListini(item) {
      return ListiniService.deleteListini(item).then(
        (del) => {
          this.del = del.data;
          return Promise.resolve({
            data: this.del,
            totalCount: this.del.length,
          });
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
});
