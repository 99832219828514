import { defineStore } from "pinia";
import IscrittiService from "@/services/iscrittiServices/IscrittiService";

export const useIscrittisStore = defineStore("AnagraficaIscrittiStore", {
  state: () => ({
    iscritti: [],
  }),

  actions: {
    randomDataIscritti() {
      return IscrittiService.randomDataIscritti().then(
        (iscritto) => {
          this.iscritti = iscritto.data;
          return Promise.resolve({
            data: this.iscritti,
            totalCount: this.iscritti.length,
          });
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getIscrittiSenzaTessera() {
      return IscrittiService.getIscrittiSenzaTessera().then(
        (iscritto) => {
          return Promise.resolve(iscritto.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getIscrittiSenzaAbbonamento() {
      return IscrittiService.getIscrittiSenzaAbbonamento().then(
        (iscritto) => {
          return Promise.resolve(iscritto.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getIscrittiSenzaCertificatoMedico() {
      return IscrittiService.getIscrittiSenzaCertificatoMedico().then(
        (iscritto) => {
          return Promise.resolve(iscritto.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getIscrittiSenzaAssicurazione() {
      return IscrittiService.getIscrittiSenzaAssicurazione().then(
        (iscritto) => {
          return Promise.resolve(iscritto.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getAllIscritti() {
      return IscrittiService.getAllIscritti().then(
        (iscritti) => {
          this.iscritti = iscritti.data;
          return Promise.resolve(this.iscritti);
        },
        (error) => {
          // const message =
          //     (error.response &&
          //         error.response.data &&
          //         error.response.data.message) ||
          //     error.message ||
          //     error.toString();
          return Promise.reject(error);
        }
      );
    },

    createIscritti(item) {
      return IscrittiService.createIscritti(item).then(
        (create) => {
          return Promise.resolve(create);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateIscritti(item) {
      return IscrittiService.updateIscritti(item).then(
        (update) => {
          return Promise.resolve(update);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteIscritti(item) {
      return IscrittiService.deleteIscritti(item).then(
        (del) => {
          return Promise.resolve(del);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
});
