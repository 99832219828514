import { defineStore } from "pinia";
import AuthService from "@/services/authServices/AuthService";
import router from "@/router";

let storedUser = localStorage.getItem("user") != 'undefined' ? localStorage.getItem("user") : null;

export const useAuthStore = defineStore("AuthStore", {
  state: () => ({
    user: storedUser ? JSON.parse(storedUser) : null,
  }),

  actions: {
    refreshToken({ commit }, accessToken) {
      commit('refreshToken', accessToken);
    },
    async logIn(email, password) {
      return AuthService.logIn(email, password).then(
        (auth) => {
          if (auth.data != undefined) {
            this.user = auth.data;
            localStorage.setItem("user", JSON.stringify(auth.data));
          } else {
            localStorage.removeItem("user");
          }
          return Promise.resolve(auth);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    async logOut() {
      localStorage.removeItem("user");
      this.user = null;

      await router.push({
        path: "/login-form",
        query: { redirect: router.currentRoute.value.path },
      });
    },
    async getUser() {
      return AuthService.getUser().then(
        (auth) => {
          return Promise.resolve(auth);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    async resetPassword(email) {
      return AuthService.resetPassword(email).then(
        (auth) => {
          return Promise.resolve(auth);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    async changePassword(email, recoveryCode) {
      return AuthService.changePassword(email, recoveryCode).then(
        (auth) => {
          return Promise.resolve(auth);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    refreshToken(state, accessToken) {
      state.user = { ...state.user, accessToken: accessToken };
    }
  },
  getters: {
    loggedIn: (state) => !!state.user,
    userIsCustomer: (state) => !!state.user?.isCustomer,
    userIsSupplier: (state) => !!state.user?.isSupplier,
    checkPermission: (state) => {
      return (validPermissions) => {
        let check = false;
        if (
          state.user &&
          state.user.permissions &&
          state.user.permissions.length > 0
        ) {
          for (const permission of state.user.permissions) {
            if (
              validPermissions.includes(permission) ||
              permission == "Admin"
            ) {
              check = true;
              break;
            }
          }
        }
        return check;
      };
    },
  },
});
