<template>
  <div id="container">
    <div class="content-block borderingOrange">
      <DxDataGrid id="dataGrid" ref="gridRef" :data-source="iscrittiData" :show-borders="true" :hoverStateEnabled="true">
        <DxSearchPanelGrid :visible="true" />
        <DxHeaderFilterGrid :visible="true" />
        <DxFilterRowGrid :visible="true" />
        <DxPagingGrid :enabled="false" />
        <DxScrollingGrid mode="virtual" />
        <DxEditingGrid :allow-adding="canRead" :allow-updating="canUpdate" :allow-deleting="canDelete"
          :allow-filtering="true" :use-icons="true" refresh-mode="refresh" mode="popup">
        </DxEditingGrid>
        <DxColumnGrid data-type="string" data-field="nome" :caption="$t('first_name')" :allow-header-filtering="false"
          :visible="true" />
        <DxColumnGrid data-type="string" data-field="cognome" :caption="$t('last_name')" :allow-header-filtering="false"
          :visible="true" />
        <DxColumnGrid data-type="string" data-field="email" :caption="$t('email')" :allow-header-filtering="false"
          :visible="true" />
        <DxColumnGrid data-field="cellulare" :caption="$t('mobile')" dataType="number" :allow-header-filtering="false"
          :visible="true" alignment="left" />

        <DxColumnGrid data-type="string" data-field="nazione" :caption="$t('state')" :allow-header-filtering="false"
          :visible="true" />
        <DxColumnGrid data-type="string" data-field="note" :caption="$t('comments')" :allow-header-filtering="false"
          :visible="true" />
        <DxColumnGrid data-type="string" data-field="extra" :caption="$t('extra')" :allow-header-filtering="false"
          :visible="true" />
        <DxColumnGrid data-field="creato_il" :caption="$t('created_on')" data-type="date" format="dd/MM/yyyy"
          :allow-header-filtering="false" :visible="true" />
        <DxColumnGrid data-type="string" data-field="qualifica" :caption="$t('position')" :allow-header-filtering="false"
          :visible="true" />
        <DxColumnGrid data-type="string" data-field="abbonamento" :caption="$t('membership')"
          :allow-header-filtering="false" />
        <DxColumnGrid cell-template="disableCell" data-field="activeValue"
          :caption="$t('status') + ' ' + $t('subscribed')" data-type="boolean" trueText="Attivo" falseText="Disabilitato"
          :allow-header-filtering="false" :visible="true" :filter-value="true">
        </DxColumnGrid>
        <template #disableCell="{ data }">
          <DxCheckBox :value="data.value" :readOnly="true" />
        </template>
        <DxColumnGrid type="buttons">
          <DxButtonGrid name="read" v-if="canRead" icon="isblank" :hint="$t('viewT')" @click="readItem($event)" />
          <DxButtonGrid name="edit" v-if="canUpdate" :hint="$t('update')" @click="updateItem($event)" />
          <DxButtonGrid name="duplicate" v-if="canDuplicate" icon="copy" :hint="$t('duplicateT')"
            @click="duplicateItem($event)" />
          <DxButtonGrid name="delete" v-if="canDelete" :hint="$t('deleteT')" @click="deleteItem($event)" />
        </DxColumnGrid>
        <DxToolbarGrid>
          <DxItemGrid location="before" template="front" />
          <DxItemGrid location="after" template="addBtn" />
          <DxItemGrid location="after" template="refreshBtn" />
        </DxToolbarGrid>

        <template #front>
          <span class="grid-front colorOrange">{{ $t("subscribed_registry") }}</span>
        </template>
        <template #addBtn>
          <DxButton name="add" v-if="canCreate" type="contained" icon="add" :hint="$t('add')" @click="createItem()">
          </DxButton>
        </template>
        <template #refreshBtn>
          <DxButton name="refresh" type="contained" icon="refresh" :hint="$t('refresh')" @click="refresh()">
          </DxButton>
        </template>
      </DxDataGrid>
      <DxPopup v-model:visible="popup" :title="popupTitle" content-template="content" :show-title="true"
        :full-screen="true" :drag-enabled="false" :hide-on-outiside-click="false" :show-close-button="false">
        <template #content>
          <DxScrollView>
            <DxForm :form-data="editedItem" label-mode="floating" class="card" ref="formRef" :col-count="3">
              <DxItemForm item-type="group" :caption="$t('registry')">
                <DxItemForm class="dx-field-value" data-field="nome" editor-type="dxTextBox"
                  :label="{ text: $t('first_name'), }" :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }">
                  <DxRequiredRule
                    :message="$t('required_error_start') + ' ' + $t('first_name') + ' ' + $t('required_error_end')" />
                  <DxStringLengthRule :max="30" message="Max 30 chars" />
                </DxItemForm>
                <DxItemForm data-field="cognome" editor-type="dxTextBox" :label="{ text: $t('last_name'), }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }">
                  <DxRequiredRule
                    :message="$t('required_error_start') + ' ' + $t('last_name') + ' ' + $t('required_error_end')" />
                  <DxStringLengthRule :max="30" message="Max 30 chars" />
                </DxItemForm>
                <DxItemForm data-field="indirizzo" editor-type="dxTextBox" :label="{ text: $t('address'), }"
                  :editor-options="{
                    showClearButton: 'true',
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }">
                </DxItemForm>

                <DxItemForm data-field="localita" editor-type="dxTextBox" :label="{ text: $t('location'), }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }">
                </DxItemForm>

                <DxItemForm data-field="provincia" editor-type="dxTextBox" :label="{ text: $t('countryside'), }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }">
                </DxItemForm>

                <DxItemForm data-field="cap" editor-type="dxNumberBox" :label="{ text: $t('postal_code'), }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }">
                </DxItemForm>
                <DxItemForm data-field="nazione" editor-type="dxTextBox" :label="{ text: $t('state'), }" :editor-options="{
                  stylingMode: 'outlined',
                  readOnly: readonly,
                }">
                </DxItemForm>
                <DxItemForm data-field="activeValue" editor-type="dxCheckBox" :label="{ text: $t('active'), }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }">
                </DxItemForm>
              </DxItemForm>
              <DxItemForm item-type="group" :caption="$t('further_info')">
                <DxItemForm data-field="email" editor-type="dxTextBox" :label="{ text: $t('email'), }" :editor-options="{
                  showClearButton: 'true',
                  stylingMode: 'outlined',
                  readOnly: readonly,
                }">
                </DxItemForm>

                <DxItemForm data-field="cellulare" editor-type="dxNumberBox" :label="{ text: $t('mobile'), }"
                  :editor-options="{
                    showClearButton: 'true',
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }">
                </DxItemForm>
                <DxItemForm data-field="codice_fiscale" editor-type="dxTextBox" :label="{ text: $t('fiscal_code'), }"
                  :editor-options="{
                    showClearButton: 'true',
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }">
                </DxItemForm>
                <DxItemForm data-field="partita_iva" editor-type="dxTextBox" :label="{ text: $t('vat_number'), }"
                  :editor-options="{
                    showClearButton: 'true',
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }" />
                <DxItemForm data-field="ragione_sociale" editor-type="dxTextBox" :label="{ text: $t('business_name'), }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }">
                </DxItemForm>
                <DxItemForm data-field="qualifica" editor-type="dxTextBox" :label="{ text: $t('position'), }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }">
                </DxItemForm>
                <DxItemForm data-field="abbonamento" editor-type="dxTextBox" :label="{ text: $t('membership'), }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: true,
                  }">
                </DxItemForm>
              </DxItemForm>

              <DxItemForm item-type="group" :caption="$t('annotations')">
                <DxItemForm data-field="note" editor-type="dxTextArea" :label="{ text: $t('comments'), }" :editor-options="{
                  showClearButton: 'true',
                  height: 100,
                  stylingMode: 'outlined',
                  readOnly: readonly,
                }" />
                <DxItemForm data-field="extra" editor-type="dxTextArea" :label="{ text: $t('extra'), }" :editor-options="{
                  showClearButton: 'true',
                  height: 100,
                  stylingMode: 'outlined',
                  readOnly: readonly,
                }" />
                <DxItemForm data-field="creato_il" editor-type="dxDateBox" :label="{ text: $t('created_on'), }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: true,
                    maskBehavior: 'true',
                    displayFormat: 'dd/MM/yyyy HH:mm:ss',
                  }">
                </DxItemForm>
                <DxItemForm data-field="aggiornato_il" editor-type="dxDateBox" :label="{ text: $t('update_on'), }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: true,
                    maskBehavior: 'true',
                    displayFormat: 'dd/MM/yyyy HH:mm:ss',
                  }">
                </DxItemForm>
                <DxItemForm data-field="eliminato_il" editor-type="dxDateBox" :label="{ text: $t('deleted_on'), }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: true,
                    maskBehavior: 'true',
                    displayFormat: 'dd/MM/yyyy HH:mm:ss',
                  }">
                </DxItemForm>
              </DxItemForm>
              <DxItemForm item-type="group" :caption="$t('picturesManagement')" :col-span="3">
                <Gallery v-if="popup" />
              </DxItemForm>
            </DxForm>
          </DxScrollView>
        </template>
        <DxToolbarItemPopup v-if="!readonly" widget="dxButton" location="after" toolbar="bottom" :options="{
          text: $t('save'),
          type: 'default',
          stylingMode: 'outlined',
          onClick: save,
        }" />
        <DxToolbarItemPopup widget="dxButton" location="after" toolbar="bottom" :options="{
          text: readonly ? $t('close') : $t('cancel'),
          type: 'default',
          stylingMode: 'outlined',
          onClick: closePopup,
        }" />
      </DxPopup>
      <DxPopup v-model:visible="popupDelete" max-width="500px" max-height="160px" title-template="front"
        content-template="body" :drag-enabled="false" :hide-on-outiside-click="false">
        <template #front>
          <div id="delDiv">
            <span id="delTitle">
              {{ $t("dialog_delete_title") }}
            </span>
          </div>
        </template>
        <template #body>
          <div id="delDiv">
            <span id="delBody">{{ $t("dialog_delete_body") }}</span>
          </div>
        </template>
        <DxToolbarItemPopup widget="dxButton" location="center" toolbar="bottom" :options="{
          text: $t('yes'),
          type: 'default',
          stylingMode: 'contained',
          onClick: deleteItemConfirm,
        }" />
        <DxToolbarItemPopup widget="dxButton" location="center" toolbar="bottom" :options="{
          text: $t('no'),
          type: 'default',
          stylingMode: 'contained',
          onClick: closeDelete,
        }" />
      </DxPopup>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import { useI18n } from "vue-i18n";

import { useIscrittisStore } from "@/stores/AnagraficaIscrittiStore";
import { settings } from "@/app-info";
import { useGalleryStore } from "../../stores/GalleryStore";
import Gallery from "../../components/gallery-component.vue";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

import notify from "devextreme/ui/notify";
import DxScrollView from "devextreme-vue/scroll-view";
import DxButton from "devextreme-vue/button";

import "devextreme-vue/text-area";
import "devextreme-vue/tag-box";

import { DxCheckBox } from "devextreme-vue/check-box";
import DxDataGrid, {
  DxColumn as DxColumnGrid,
  DxSearchPanel as DxSearchPanelGrid,
  DxHeaderFilter as DxHeaderFilterGrid,
  DxFilterRow as DxFilterRowGrid,
  DxPaging as DxPagingGrid,
  DxEditing as DxEditingGrid,
  DxButton as DxButtonGrid,
  DxItem as DxItemGrid,
  DxToolbar as DxToolbarGrid,
  DxScrolling as DxScrollingGrid,
} from "devextreme-vue/data-grid";

import DxForm, {
  DxItem as DxItemForm,
  // DxCompareRule,
  DxRequiredRule,
  //DxEmailRule,
  DxStringLengthRule,
} from "devextreme-vue/form";

import DxPopup, {
  DxToolbarItem as DxToolbarItemPopup,
} from "devextreme-vue/popup";

export default {
  setup() {
    const { t } = useI18n();
    const WS_URL = settings.wsUrl;

    const defaultItem = ref({
      id: "",
      nome: "",
      cognome: "",
      ragione_sociale: "",
      codice_fiscale: "",
      partita_iva: null,
      qualifica: null,
      indirizzo: "",
      localita: "",
      provincia: "",
      cap: null,
      nazione: "",
      abbonamento: "",
      email: "",
      cellulare: null,
      note: null,
      extra: null,
      creato_il: "",
      aggiornato_il: "",
      eliminato_il: "",
      activeValue: true,
      tenant: null,
    });

    const editedItem = ref(null);
    const canCreate = ref(false);
    const canRead = ref(false);
    const canUpdate = ref(false);
    const canDuplicate = ref(false);
    const canDelete = ref(false);

    const gridRef = ref(null);
    const formRef = ref(null);
    const iscrittiDataRef = ref(null);
    const popup = ref(false);
    const popupDelete = ref(false);
    const popupTitle = ref("");
    const formData = ref(null);
    const readonly = ref(false);
    const index = ref(-1);
    const connectionStarted = ref(false);
    const actionType = ref("");

    const galleryItem = ref([]);
    const galleryStore = useGalleryStore();

    const { gallerySrc, galleryReadonly } = storeToRefs(galleryStore);

    const anagraficaIscrittiStore = useIscrittisStore();
    const { iscritti } = storeToRefs(anagraficaIscrittiStore);
    const store = new CustomStore({
      load: () => anagraficaIscrittiStore.getAllIscritti(),
      key: "id",
    });

    const iscrittiData = ref(
      new DataSource({
        store: store,
        reshapeOnPush: true,
      })
    );

    onMounted(async () => {
      let user = JSON.parse(localStorage.getItem("user"));
      if (user.permissions.includes("Admin")) {
        canCreate.value = true;
        canRead.value = true;
        canUpdate.value = true;
        canDuplicate.value = true;
        canDelete.value = true;
      }
      if (user.permissions.includes("Role")) {
        canCreate.value = true;
        canRead.value = true;
        canUpdate.value = true;
        canDuplicate.value = true;
      }
      if (user.permissions.includes("User")) {
        canRead.value = true;
      }
      connectionStarted.value = true;

      const hubConnection = new HubConnectionBuilder()
        .withUrl(WS_URL + "/anagraficaIscrittiHub", {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        })
        .build();

      hubConnection.start().then(() => {
        let userCookie = JSON.parse(localStorage.getItem("user"));
        hubConnection.on("insert", (tenant, name, data) => {
          if (
            (userCookie.tenant === null && name.toUpperCase() == "DEFAULT") ||
            userCookie.tenant.id === tenant
          ) {
            data = JSON.parse(data);
            store.push([{ type: "insert", key: data.id, data }]);
          }
        });
        hubConnection.on("update", (tenant, name, data) => {
          if (
            (userCookie.tenant === null && name.toUpperCase() == "DEFAULT") ||
            userCookie.tenant.id === tenant
          ) {
            data = JSON.parse(data);
            store.push([{ type: "update", key: data.id, data }]);
          }
        });
        hubConnection.on("remove", (tenant, name, id) => {
          if (
            (userCookie.tenant === null && name.toUpperCase() == "DEFAULT") ||
            userCookie.tenant.id === tenant
          ) {
            store.push([{ type: "remove", key: id }]);
          }
        });
      });
    });

    function createItem() {
      popupTitle.value = t("new") + " " + t("subscribed");
      actionType.value = "create";
      editedItem.value = JSON.parse(JSON.stringify(defaultItem.value));
      openPopup();
    }
    async function readItem(event) {
      popupTitle.value = t("viewT") + " " + t("subscribed");
      actionType.value = "read";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      await galleryStore.getGallery("anagrafica_iscritti", editedItem.value.id);
      index.value = event.row.dataIndex;
      readonly.value = true;
      openPopup();
    }
    async function updateItem(event) {
      popupTitle.value = t("update") + " " + t("subscribed");
      actionType.value = "update";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      await galleryStore.getGallery("anagrafica_iscritti", editedItem.value.id);
      index.value = event.row.dataIndex;
      openPopup();
    }
    async function duplicateItem(event) {
      popupTitle.value = t("duplicate") + " " + t("subscribed");
      actionType.value = "duplicate";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      await galleryStore.getGallery("anagrafica_iscritti", editedItem.value.id);
      editedItem.value.id = null;
      editedItem.value.nome = null;
      editedItem.value.cognome = null;
      editedItem.value.email = null;
      editedItem.value.codice_fiscale = null;
      editedItem.value.partita_iva = null;
      editedItem.value.abbonamento = null;
      editedItem.value.cellulare = null;
      editedItem.value.creato_il = null;
      editedItem.value.aggiornato_il = null;
      editedItem.value.eliminato_il = null;
      editedItem.value.activeValue = true;
      openPopup();
    }
    function deleteItem(event) {
      actionType.value = "delete";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      index.value = event.row.dataIndex;
      openDelete();
    }
    async function deleteItemConfirm() {
      await anagraficaIscrittiStore.deleteIscritti(editedItem.value);
      let item = JSON.parse(JSON.stringify(editedItem.value));
      store.push([{ type: "remove", key: item.id }]);
      showToast("success");
      closeDelete();
    }

    async function save() {
      if (formRef.value.instance.validate().isValid) {
        if (index.value > -1) {
          await anagraficaIscrittiStore
            .updateIscritti(editedItem.value)
            .then(async (data) => {
              for (let item of gallerySrc.value) {
                galleryItem.value.push({
                  Id: item.id,
                  Image: item.image,
                  TableName: "anagrafica_iscritti",
                  TableId: data.data.id,
                });
              }
              await galleryStore
                .updateGallery(editedItem.value.id, galleryItem.value)
                .then(() => {
                  let item = JSON.parse(JSON.stringify(data.data));
                  store.push([{ type: "update", key: item.id, data: item }]);
                  showToast("success");
                  closePopup();
                })
                .catch((error) => {
                  console.log(error);
                  let message = error.response.data.title
                    ? error.response.data.title
                    : error.response.data.message;
                  showToast("error", message);
                });
            })
            .catch((error) => {
              console.log(error);
              let message = error.response.data.title
                ? error.response.data.title
                : error.response.data.message;
              showToast("error", message);
            });
        } else {
          editedItem.value.title = "them";
          await anagraficaIscrittiStore
            .createIscritti(editedItem.value)
            .then(async (data) => {
              for (let item of gallerySrc.value) {
                galleryItem.value.push({
                  Id: item.id,
                  Image: item.image,
                  TableName: "anagrafica_iscritti",
                  TableId: data.data.id,
                });
              }
              await galleryStore
                .createGallery(galleryItem.value)
                .then(() => {
                  let item = JSON.parse(JSON.stringify(data.data));
                  store.push([{ type: "insert", key: item.id, data: item }]);
                  showToast("success");
                  closePopup();
                })
                .catch((error) => {
                  console.log(error);
                  let message = error.response.data.title
                    ? error.response.data.title
                    : error.response.data.message;
                  showToast("error", message);
                });
            })
            .catch((error) => {
              console.log(error);
              let message = error.response.data.title
                ? error.response.data.title
                : error.response.data.message;
              showToast("error", message);
            });
        }
      }
    }

    function showToast(type, error = null) {
      let message;
      if (type == "success")
        message = t(actionType.value) + " " + t("successful");
      else message = error;
      notify(
        {
          message: message,
          maxWidth: 600,
        },
        type,
        4000
      );
    }

    function refresh() {
      iscrittiData.value.reload();
    }

    function fillData() {
      anagraficaIscrittiStore.randomDataIscritti();
    }

    function openPopup() {
      popup.value = true;
      galleryReadonly.value = readonly;
    }
    function closePopup() {
      iscrittiData.value.reload();
      formRef.value.instance.resetValues();
      index.value = -1;
      readonly.value = false;
      galleryReadonly.value = false;
      editedItem.value = null;
      actionType.value = "";
      popup.value = false;
      refresh();
    }
    function openDelete() {
      popupDelete.value = true;
    }
    function closeDelete() {
      popupDelete.value = false;
      index.value = -1;
      actionType.value = "";
    }

    function passMatch() {
      return editedItem.value
        ? editedItem.value.password
          ? editedItem.value.password
          : ""
        : "";
    }

    async function getPdf() {
      let pdf = await anagraficaIscrittiStore.getPdf(iscritti.value);
      var binaryData = [];
      binaryData.push(pdf.data);
      window.open(
        URL.createObjectURL(new Blob(binaryData, { type: "application/pdf" })),
        "_blank"
      );
    }

    return {
      // VARIABILI
      // users,
      // usersData,
      //roles,
      defaultItem,
      editedItem,
      popup,
      popupDelete,
      popupTitle,
      formData,
      readonly,
      index,
      gridRef,
      formRef,
      iscrittiDataRef,
      connectionStarted,
      actionType,
      iscritti,
      iscrittiData,
      gallerySrc,
      galleryReadonly,

      // PERMESSI
      canCreate,
      canRead,
      canUpdate,
      canDuplicate,
      canDelete,

      // FUNZIONI
      openPopup,
      closePopup,
      openDelete,
      closeDelete,
      passMatch,
      getPdf,

      // CRUD
      createItem,
      readItem,
      updateItem,
      duplicateItem,
      deleteItem,
      deleteItemConfirm,
      refresh,
      fillData,
      //changelanguage,
      save,
    };
  },
  components: {
    // CUSTOM COMPONENTS
    Gallery,

    // DEVEXTREME COMPONENTS
    DxDataGrid,
    DxColumnGrid,
    DxSearchPanelGrid,
    DxHeaderFilterGrid,
    DxFilterRowGrid,
    DxPagingGrid,
    DxEditingGrid,
    DxToolbarGrid,
    DxItemGrid,
    DxButtonGrid,
    DxScrollingGrid,
    DxCheckBox,
    DxForm,
    DxItemForm,
    DxRequiredRule,
    DxStringLengthRule,
    // DxCompareRule,
    //DxEmailRule,
    //DxCheckBox,
    DxScrollView,
    DxPopup,
    DxToolbarItemPopup,
    DxButton,
  },
};
</script>

<style scoped>
#dataGrid {
  height: 93vh;
}

#delDiv {
  text-align: center;
}

#delTitle {
  font-size: 30px;
  margin: 300px auto;
}

#delBody {
  font-size: 20px;
}

.card {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 20px;
  margin: 1px;
  border-radius: 5px;
  border: 1px solid #596980;
}

.grid-front {
  font-size: 36px;
}

.dx-data-row .cell-note .WrappedColumnClass {
  white-space: normal;
  word-wrap: break-word;
}

:deep(.dx-datagrid-header-panel .dx-toolbar) {
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

:deep(.dx-toolbar .dx-toolbar-before) {
  margin-top: -6px;
}

:deep(.dx-header-row) {
  color: #ff5722
}
</style>
