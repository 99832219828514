<template>
  <div>
    <h2 class="content-block">{{ $t("profile") }}</h2>

    <div class="content-block dx-card responsive-paddings">
      <Gallery />
    </div>

    <div class="content-block dx-card responsive-paddings">
      <div class="form-row">
        <DxForm :form-data="user" label-mode="floating" ref="userDataRef">
          <DxItemForm item-type="group" :col-span="5" :col-count="5">
            <DxItemForm class="dx-field-value" data-field="firstName" editor-type="dxTextBox" :label="{
              text: $t('name'),
            }" :editor-options="{
  stylingMode: 'outlined',
  readOnly: true,
}">
              <DxRequiredRule :message="$t('requiredErrorStart') +
                ' ' +
                $t('name') +
                ' ' +
                $t('requiredErrorEnd')
                " />
            </DxItemForm>
            <DxItemForm data-field="lastName" editor-type="dxTextBox" :label="{
              text: $t('lastName'),
            }" :editor-options="{
  stylingMode: 'outlined',
  readOnly: true,
}">
              <DxRequiredRule :message="$t('requiredErrorStart') +
                ' ' +
                $t('lastName') +
                ' ' +
                $t('requiredErrorEnd')
                " />
            </DxItemForm>
            <DxItemForm data-field="email" editor-type="dxTextBox" :label="{
              text: $t('email'),
            }" :editor-options="{
  stylingMode: 'outlined',
  mode: 'email',
  readOnly: true,
}">
              <DxRequiredRule :message="$t('requiredErrorStart') +
                ' ' +
                $t('email') +
                ' ' +
                $t('requiredErrorEnd')
                " />
            </DxItemForm>
            <DxItemForm data-field="roles" editor-type="dxTagBox" :label="{
              text: $t('roles'),
            }" :editor-options="{
  dataSource: roles,
  displayExpr: 'roleName',
  valueExpr: 'id',
  placeholder:
    $t('select_place_holder') + ' ' + $t('role') + '...',
  searchEnabled: true,
  readOnly: true,
}">
              <DxRequiredRule :message="$t('requiredErrorStart') +
                ' ' +
                $t('roles') +
                ' ' +
                $t('requiredErrorEnd')
                " />
            </DxItemForm>
            <DxItemForm data-field="isActive" editor-type="dxCheckBox" :label="{
              text: ' ',
            }" :editor-options="{
  stylingMode: 'outlined',
  readOnly: true,
  text: $t('active'),
}">
            </DxItemForm>
          </DxItemForm>
        </DxForm>
        <DxPopup v-model:visible="popup" :title="popupTitle" content-template="content" :show-title="true"
          :full-screen="true" :drag-enabled="false" :hide-on-outiside-click="false">
          <template #content>
            <DxScrollView>
              <DxForm :form-data="editedItem" label-mode="floating" class="card" ref="formRef">
                <DxItemForm item-type="group" :caption="$t('userInfo')">
                  <DxItemForm class="dx-field-value" data-field="firstName" editor-type="dxTextBox" :label="{
                    text: $t('name'),
                  }" :editor-options="{
  stylingMode: 'outlined',
}">
                    <DxRequiredRule :message="$t('requiredErrorStart') +
                      ' ' +
                      $t('name') +
                      ' ' +
                      $t('requiredErrorEnd')
                      " />
                  </DxItemForm>
                  <DxItemForm data-field="lastName" editor-type="dxTextBox" :label="{
                    text: $t('lastName'),
                  }" :editor-options="{
  stylingMode: 'outlined',
}">
                    <DxRequiredRule :message="$t('requiredErrorStart') +
                      ' ' +
                      $t('lastName') +
                      ' ' +
                      $t('requiredErrorEnd')
                      " />
                  </DxItemForm>
                  <DxItemForm data-field="email" editor-type="dxTextBox" :label="{
                    text: $t('email'),
                  }" :editor-options="{
  stylingMode: 'outlined',
  mode: 'email',
}">
                    <DxRequiredRule :message="$t('requiredErrorStart') +
                      ' ' +
                      $t('email') +
                      ' ' +
                      $t('requiredErrorEnd')
                      " />
                  </DxItemForm>
                  <DxItemForm data-field="roles" editor-type="dxTagBox" :label="{
                    text: $t('roles'),
                  }" :editor-options="{
  dataSource: roles,
  displayExpr: 'roleName',
  valueExpr: 'id',
  placeholder:
    $t('select_place_holder') + ' ' + $t('role') + '...',
  searchEnabled: true,
}">
                    <DxRequiredRule :message="$t('requiredErrorStart') +
                      ' ' +
                      $t('roles') +
                      ' ' +
                      $t('requiredErrorEnd')
                      " />
                  </DxItemForm>
                  <DxItemForm data-field="isActive" editor-type="dxCheckBox" :label="{
                    text: ' ',
                  }" :editor-options="{
  stylingMode: 'outlined',
  text: $t('active'),
}">
                  </DxItemForm>
                </DxItemForm>
                <DxItemForm item-type="group" :caption="$t('password')">
                  <DxItemForm data-field="password" editor-type="dxTextBox" :label="{
                    text: $t('password'),
                  }" :editor-options="{
  stylingMode: 'outlined',
  mode: 'password',
}">
                    <DxStringLengthRule :ignore-empty-value="true" :min="6" :message="$t('passwordLengthError')" />
                  </DxItemForm>
                  <DxItemForm data-field="ConfirmPassword" editor-type="dxTextBox" :label="{
                    text: $t('confirm_password'),
                  }" :editor-options="{
  stylingMode: 'outlined',
  mode: 'password',
}">
                    <DxCompareRule :ignore-empty-value="true" :comparison-target="passMatch"
                      :message="$t('passwordMatchError')" />
                  </DxItemForm>
                </DxItemForm>
                <DxItemForm item-type="group" :caption="$t('picturesManagement')">
                  <Gallery v-if="popup" />
                </DxItemForm>
              </DxForm>
            </DxScrollView>

            <DxLoadPanel v-model:visible="loading" :message="$t('loading')" :show-pane="true" :shading="true"
              :hide-on-outside-click="false" shading-color="rgba(0,0,0,0.3)" />
          </template>
          <DxToolbarItemPopup widget="dxButton" location="after" toolbar="bottom" :options="{
            text: $t('save'),
            type: 'default',
            stylingMode: 'outlined',
            onClick: save,
          }" />
          <DxToolbarItemPopup widget="dxButton" location="after" toolbar="bottom" :options="{
            text: $t('cancel'),
            type: 'default',
            stylingMode: 'outlined',
            onClick: closePopup,
          }" />
        </DxPopup>
      </div>
      <div class="btn-row">
        <DxButton class="btn" name="edit" icon="edit" :text="$t('update')" type="default" styling-mode="outlined"
          @click="updateItem()" />
        <DxButton class="btn" name="pdf" icon="pdffile" :text="$t('generatePdf')" type="default" styling-mode="outlined"
          @click="getPdf()" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useUsersStore } from "../stores/UsersStore";
import { useRolesStore } from "../stores/RolesStore";
import { useGalleryStore } from "../stores/GalleryStore";
import { storeToRefs } from "pinia";

import notify from "devextreme/ui/notify";
import DxForm, {
  DxItem as DxItemForm,
  DxRequiredRule,
  DxCompareRule,
  DxStringLengthRule,
} from "devextreme-vue/form";
import DxScrollView from "devextreme-vue/scroll-view";
import DxButton from "devextreme-vue/button";
import DxPopup, {
  DxToolbarItem as DxToolbarItemPopup,
} from "devextreme-vue/popup";
import DxLoadPanel from "devextreme-vue/load-panel";

import Gallery from "../components/gallery-component.vue";

import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    const usersStore = useUsersStore();
    const rolesStore = useRolesStore();
    const galleryStore = useGalleryStore();

    const formRef = ref(null);
    const userDataRef = ref(null);
    const popup = ref(false);
    const loading = ref(false);
    const popupTitle = ref("");

    const editedItem = ref(null);
    const galleryItem = ref([]);

    const { user } = storeToRefs(usersStore);
    const { roles } = storeToRefs(rolesStore);
    const { gallerySrc, galleryReadonly } = storeToRefs(galleryStore);

    onMounted(async () => {
      let userCache = JSON.parse(localStorage.getItem("user"));

      await usersStore.getUser(userCache);
      await rolesStore.getRoles();
      await galleryStore.getGallery("Accounts", user.value.id);
      galleryReadonly.value = true;
    });

    async function updateItem() {
      loading.value = true;

      popupTitle.value = t("update") + " " + t("user");
      editedItem.value = JSON.parse(JSON.stringify(user.value));
      await galleryStore.getGallery("Accounts", editedItem.value.id);

      loading.value = false;
      openPopup();
      galleryReadonly.value = false;
    }

    async function save() {
      if (formRef.value.instance.validate().isValid) {
        await usersStore
          .updateUser(editedItem.value)
          .then(async (data) => {
            for (let item of gallerySrc.value) {
              galleryItem.value.push({
                Id: item.id,
                Image: item.image,
                TableName: "Accounts",
                TableId: data.data.id,
              });
            }
            await galleryStore
              .updateGallery(editedItem.value.id, galleryItem.value)
              .then(() => {
                userDataRef.value.instance.option("formData", data.data);
                user.value = JSON.parse(JSON.stringify(data.data));
                showToast("success");
                closePopup();
              })
              .catch((error) => {
                console.log(error);
                let message = error.response.data.title
                  ? error.response.data.title
                  : error.response.data.message;
                showToast("error", message);
                return;
              });
          })
          .catch((error) => {
            console.log(error);
            let message = error.response.data.title
              ? error.response.data.title
              : error.response.data.message;
            showToast("error", message);
          });
      }
    }

    function openPopup() {
      galleryItem.value = [];
      popup.value = true;
    }
    function closePopup() {
      formRef.value.instance.resetValues();
      galleryReadonly.value = true;
      popup.value = false;
    }

    async function getPdf() {
      let pdf = await usersStore.getPdf(user.value);
      var binaryData = [];
      binaryData.push(pdf.data);
      window.open(
        URL.createObjectURL(new Blob(binaryData, { type: "application/pdf" })),
        "_blank"
      );
    }

    function showToast(type, error = null) {
      let message;
      if (type == "success") message = t("update") + " " + t("successful");
      else message = error;
      notify(
        {
          message: message,
          maxWidth: 600,
        },
        type,
        4000
      );
    }
    function passMatch() {
      return editedItem.value
        ? editedItem.value.password
          ? editedItem.value.password
          : ""
        : "";
    }

    return {
      user,
      roles,
      gallerySrc,
      galleryReadonly,
      editedItem,
      galleryItem,
      formRef,
      userDataRef,
      popup,
      loading,
      popupTitle,

      passMatch,
      openPopup,
      closePopup,
      updateItem,
      save,
      getPdf,
    };
  },
  components: {
    // CUSTOM COMPONENTS
    Gallery,

    // NATIVE COMPONENTS
    DxForm,
    DxItemForm,
    DxRequiredRule,
    DxCompareRule,
    DxStringLengthRule,
    DxScrollView,
    DxButton,
    DxPopup,
    DxToolbarItemPopup,
    DxLoadPanel,
  },
};
</script>

<style scoped lang="scss">
:deep(.dx-texteditor-container) {
  height: 42px;
}

.form-row {
  display: block;
  margin: auto;
}

.btn-row {
  display: block;
  margin-top: 25px;
  text-align: right;
}

.btn {
  margin: 2px;
}
</style>
