<template>
  <DxDashboardControl
    style="height: 800px; display: 'block'; width: '100%'"
    :endpoint="baseUrl + '/api/dashboard'"
    workingMode="Viewer"
    @dashboardInitializing="beforeRender"
    dashboardId="DashboardUtenti"
  >
  </DxDashboardControl>
</template>

<script>
import { onMounted } from "vue";
import { DxDashboardControl } from "devexpress-dashboard-vue";
import { DashboardPanelExtension } from "devexpress-dashboard/common";
import { settings } from "../app-info";
// import { ResourceManager } from 'devexpress-dashboard';
// import { locale } from 'devextreme/localization';

export default {
  setup() {
    const baseUrl = settings.apiUrl;

    onMounted(() => {
      // ResourceManager.setLocalizationMessages(require('../../js/dx-analytics-core.it.json'));
      // ResourceManager.setLocalizationMessages(require('../../js/dx-dashboard.it.json'));
      // ResourceManager.setLocalizationMessages(require('../../js/dx-reporting.it.json'));
      // ResourceManager.setLocalizationMessages(require('../../js/dx-rich.it.json'));
      // ResourceManager.setLocalizationMessages(require('../../js/dx-spreadsheet.it.json'));
      // locale('it');
    });

    function beforeRender(e) {
      let dashboardControl = e.component;
      //console.log(e);
      let panel = new DashboardPanelExtension(dashboardControl);
      panel.allowSwitchToDesigner(false);
      dashboardControl.registerExtension(panel);
    }

    return {
      baseUrl,

      beforeRender,
    };
  },
  components: {
    DxDashboardControl,
  },
};
</script>
<style scoped>
  :deep(.dx-dashboard-control) {
    background-color:#fff
  }
</style>
