<template>
  <div id="container">
    <div class="content-block borderingOrange">
      <DxDataGrid id="dataGrid" ref="gridRef" :data-source="abbonamentiData" :show-borders="true">
        <DxSearchPanelGrid :visible="true" />
        <DxHeaderFilterGrid :visible="true" />
        <DxFilterRowGrid :visible="true" />
        <DxPagingGrid :enabled="false" />
        <DxScrollingGrid mode="virtual" />
        <DxEditingGrid :allow-adding="canRead" :allow-updating="canUpdate" :allow-deleting="canDelete"
          :allow-filtering="true" :use-icons="true" refresh-mode="refresh" mode="popup">
        </DxEditingGrid>
        <DxColumnGrid data-type="string" data-field="nome_iscritto" :caption="$t('subscribed_name')"
          :allow-header-filtering="false" :visible="true" />

        <DxColumnGrid data-type="string" :data-source="listini" data-field="nome_listino"
          :caption="$t('membership_types')" :allow-header-filtering="false" :visible="true" />

        <DxColumnGrid data-field="data_abbonamento" :caption="$t('membership_date')" data-type="date" format="dd/MM/yyyy"
          :allow-header-filtering="false" :visible="true" />
        <DxColumnGrid data-field="data_scadenza_abbonamento" :caption="$t('membership_expiration_date')" data-type="date"
          format="dd/MM/yyyy" :allow-header-filtering="false" :visible="true" />
        <DxColumnGrid data-field="creato_il" :caption="$t('created_on')" data-type="date" format="dd/MM/yyyy"
          :allow-header-filtering="false" :visible="true" />
        <DxColumnGrid data-field="ingressi_rimanenti" :caption="'Ingressi rimanenti'" :allow-header-filtering="true"
          :visible="true" />
        <DxColumnGrid cell-template="disableCell" data-field="activeValue"
          :caption="$t('status') + ' ' + $t('membership')" data-type="boolean" trueText="Attivo" falseText="Disabilitato"
          :allow-header-filtering="false" :visible="true" :filter-value="true">
        </DxColumnGrid>
        <template #disableCell="{ data }">
          <DxCheckBox :value="data.value" :readOnly="true" />
        </template>
        <DxColumnGrid type="buttons">
          <DxButtonGrid name="read" v-if="canRead" icon="isblank" :hint="$t('viewT')" @click="readItem($event)" />
          <DxButtonGrid name="edit" v-if="canUpdate" :hint="$t('update')" @click="updateItem($event)" />
          <DxButtonGrid name="duplicate" v-if="canDuplicate" icon="copy" :hint="$t('duplicateT')"
            @click="duplicateItem($event)" />
          <DxButtonGrid name="delete" v-if="canDelete" :hint="$t('deleteT')" @click="deleteItem($event)" />
        </DxColumnGrid>
        <DxToolbarGrid>
          <DxItemGrid location="before" template="front" />
          <DxItemGrid location="after" template="addBtn" />
          <DxItemGrid location="after" template="refreshBtn" />
        </DxToolbarGrid>
        <template #front>
          <span class="grid-front colorOrange">{{ $t("memberships_store") }}</span>
        </template>
        <template #addBtn>
          <DxButton name="add" v-if="canCreate" type="contained" icon="add" :hint="$t('add')" @click="createItem()">
          </DxButton>
        </template>
        <template #refreshBtn>
          <DxButton name="refresh" type="contained" icon="refresh" :hint="$t('refresh')" @click="refresh()">
          </DxButton>
        </template>
      </DxDataGrid>
      <DxPopup v-model:visible="popup" :title="popupTitle" content-template="content" :show-title="true"
        :full-screen="true" :drag-enabled="false" :hide-on-outiside-click="false" :show-close-button="false">
        <template #content>
          <DxScrollView>
            <DxForm :form-data="editedItem" label-mode="floating" class="membership" ref="formRef" :col-count="2">
              <DxItemForm item-type="group" :caption="$t('membership_data')">
                <DxItemForm v-if="actionType === 'read' || actionType === 'update'" data-field="nome_iscritto"
                  editor-type="dxTextBox" :label="{ text: $t('subscribed_name'), }" :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: true,
                  }" />

                <DxItemForm v-else data-field="user_id" editor-type="dxSelectBox"
                  :label="{ text: $t('subscribed_name'), }" :editor-options="{
                    dataSource: iscrittiData,
                    displayExpr: 'nome_intero',
                    valueExpr: 'id',
                    placeholder: $t('select_place_holder') + ' ' + $t('subscribed_name') + '...',
                    searchEnabled: true,
                    readOnly: readonly,
                  }">
                  <DxRequiredRule
                    :message="$t('required_error_start') + ' ' + $t('subscribeds') + ' ' + $t('required_error_end')" />
                </DxItemForm>

                <DxItemForm data-field="tipologia_abbonamento_id" editor-type="dxSelectBox"
                  :label="{ text: $t('price_list_name'), }" :editor-options="{
                    dataSource: listiniData,
                    displayExpr: 'nome_listino',
                    valueExpr: 'id',
                    placeholder: $t('select_place_holder') + ' ' + $t('price_list_name') + '...',
                    searchEnabled: true,
                    readOnly: readonly,
                    onSelectionChanged: (e) => nomeListinoChanged(e)
                  }">
                </DxItemForm>

                <DxItemForm data-field="data_abbonamento" editor-type="dxDateBox"
                  :label="{ text: $t('membership_date'), }" :editor-options="{
                    showClearButton: 'true',
                    type: 'date',
                    placeholder: 'please, insert a valid date',
                    stylingMode: 'outlined',
                    readOnly: readonly,
                    maskBehavior: 'true',
                    displayFormat: 'dd/MM/yyyy',
                    onValueChanged(e) {
                      if (openingPopupDA) return
                      const date = new Date(e.value)
                      const localDate = new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate(),
                        12
                      );
                      editedItem.data_abbonamento = new Date(localDate);
                      if (infoListino?.tipologia_calcolo == 'Forfait') {
                        localDate.setDate(localDate.getDate() + infoListino.durata)
                        editedItem.data_scadenza_abbonamento = new Date(localDate)
                      }
                    }
                  }">
                  <DxRequiredRule
                    :message="$t('required_error_start') + ' ' + $t('expiration_date') + ' ' + $t('required_error_end')" />
                </DxItemForm>

                <DxItemForm data-field="data_scadenza_abbonamento" editor-type="dxDateBox"
                  :label="{ text: $t('membership_expiration_date'), }" :editor-options="{
                    showClearButton: 'true',
                    type: 'date',
                    placeholder: 'please, insert a valid date',
                    stylingMode: 'outlined',
                    readOnly: readonly,
                    maskBehavior: 'true',
                    displayFormat: 'dd/MM/yyyy',
                  }">
                  <DxRequiredRule
                    :message="$t('required_error_start') + ' ' + $t('expiration_date') + ' ' + $t('required_error_end')" />
                </DxItemForm>
              </DxItemForm>
              <DxItemForm item-type="group" :caption="$t('further_info')">
                <DxItemForm data-field="creato_il" editor-type="dxDateBox" :label="{ text: $t('created_on'), }"
                  :editor-options="{
                    readOnly: true,
                    stylingMode: 'outlined',
                    maskBehavior: 'true',
                    displayFormat: 'dd/MM/yyyy        HH:mm:ss',
                  }">
                </DxItemForm>
                <DxItemForm data-field="aggiornato_il" editor-type="dxDateBox" :label="{ text: $t('update_on'), }"
                  :editor-options="{
                    readOnly: true,
                    stylingMode: 'outlined',
                    maskBehavior: 'true',
                    displayFormat: 'dd/MM/yyyy        HH:mm:ss',
                  }">
                </DxItemForm>

                <DxItemForm data-field="eliminato_il" editor-type="dxDateBox" :label="{ text: $t('deleted_on'), }"
                  :editor-options="{
                    readOnly: true,
                    stylingMode: 'outlined',
                    maskBehavior: 'true',
                    displayFormat: 'dd/MM/yyyy        HH:mm:ss',
                  }">
                </DxItemForm>

                <DxItemForm data-field="activeValue" editor-type="dxCheckBox" :label="{ text: $t('active'), }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }">
                </DxItemForm>
              </DxItemForm>
            </DxForm>
          </DxScrollView>
        </template>
        <DxToolbarItemPopup v-if="!readonly" widget="dxButton" location="after" toolbar="bottom" :options="{
          text: $t('save'),
          type: 'default',
          stylingMode: 'outlined',
          onClick: save,
        }" />
        <DxToolbarItemPopup widget="dxButton" location="after" toolbar="bottom" :options="{
          text: readonly ? $t('close') : $t('cancel'),
          type: 'default',
          stylingMode: 'outlined',
          onClick: closePopup,
        }" />
      </DxPopup>
      <DxPopup v-model:visible="popupDelete" max-width="500px" max-height="160px" title-template="front"
        content-template="body" :drag-enabled="false" :hide-on-outiside-click="false">
        <template #front>
          <div id="delDiv">
            <span id="delTitle">
              {{ $t("dialog_delete_title") }}
            </span>
          </div>
        </template>
        <template #body>
          <div id="delDiv">
            <span id="delBody">{{ $t("dialog_delete_body") }}</span>
          </div>
        </template>
        <DxToolbarItemPopup widget="dxButton" location="center" toolbar="bottom" :options="{
          text: $t('yes'),
          type: 'default',
          stylingMode: 'contained',
          onClick: deleteItemConfirm,
        }" />
        <DxToolbarItemPopup widget="dxButton" location="center" toolbar="bottom" :options="{
          text: $t('no'),
          type: 'default',
          stylingMode: 'contained',
          onClick: closeDelete,
        }" />
      </DxPopup>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from "vue";
import { storeToRefs } from "pinia";
import CustomStore from "devextreme/data/custom_store";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";

import DataSource from "devextreme/data/data_source";

import notify from "devextreme/ui/notify";
import "devextreme-vue/tag-box";

import DxDataGrid, {
  DxColumn as DxColumnGrid,
  DxSearchPanel as DxSearchPanelGrid,
  DxHeaderFilter as DxHeaderFilterGrid,
  DxFilterRow as DxFilterRowGrid,
  DxPaging as DxPagingGrid,
  DxEditing as DxEditingGrid,
  DxButton as DxButtonGrid,
  DxItem as DxItemGrid,
  DxToolbar as DxToolbarGrid,
  //DxLookup as DxLookupGrid,
  DxScrolling as DxScrollingGrid,
} from "devextreme-vue/data-grid";

import { DxCheckBox } from "devextreme-vue/check-box";
//import DxDateBox from 'devextreme-vue/date-box';

import DxForm, {
  DxItem as DxItemForm,
  DxRequiredRule,
  // DxCompareRule,
  //DxStringLengthRule,
} from "devextreme-vue/form";
import DxScrollView from "devextreme-vue/scroll-view";
import DxButton from "devextreme-vue/button";
import DxPopup, {
  DxToolbarItem as DxToolbarItemPopup,
} from "devextreme-vue/popup";

import { useI18n } from "vue-i18n";
import { useAbbonamentiStore } from "@/stores/ArchivioAbbonamentiStore";
import { useIscrittisStore } from "@/stores/AnagraficaIscrittiStore";
import { useListinisStore } from "@/stores/ArchivioListiniStore";

import { settings } from "@/app-info";

export default {
  setup() {
    const { t } = useI18n();

    const WS_URL = settings.wsUrl;
    const archivioAbbonamentiStore = useAbbonamentiStore();
    const anagraficaIscrittiStore = useIscrittisStore();
    const archivioListiniStore = useListinisStore();

    const now = new Date();

    const abbonamento = now;

    const scadenza = new Date(
      abbonamento.getFullYear() + 1,
      abbonamento.getMonth(),
      abbonamento.getDate()
    );

    const defaultItem = ref({
      id: "",
      tenant_id: "",
      user_id: "",
      data_abbonamento: abbonamento,
      data_scadenza_abbonamento: scadenza,
      nome_iscritto: "",
      tipologia_abbonamento_id: null,
      creato_il: "",
      aggiornato_il: "",
      eliminato_il: "",
      rinnovo_automatico: false,
      activeValue: true,
      tenant: null,
    });
    const editedItem = ref(null);
    const canCreate = ref(false);
    const canRead = ref(false);
    const canUpdate = ref(false);
    const canDuplicate = ref(false);
    const canDelete = ref(false);

    const gridRef = ref(null);
    const formRef = ref(null);
    const popup = ref(false);
    const popupDelete = ref(false);
    const popupTitle = ref("");
    const formData = ref(null);
    const readonly = ref(false);
    const index = ref(-1);
    const connectionStarted = ref(false);
    const actionType = ref("");
    const infoListino = ref(null);
    const openingPopupDA = ref(false);


    const { abbonamenti } = storeToRefs(archivioAbbonamentiStore);

    const store = new CustomStore({
      load: () => archivioAbbonamentiStore.getAllAbbonamenti(),
      key: "id",
    });

    const abbonamentiData = ref(
      new DataSource({
        store: store,
        reshapeOnPush: true,
      })
    );

    const { iscritti } = storeToRefs(anagraficaIscrittiStore);

    const storeIscritti = new CustomStore({
      load: () => anagraficaIscrittiStore.getIscrittiSenzaAbbonamento(),
      key: "id",
      loadMode: "raw",
    });

    const iscrittiData = ref(
      new DataSource({
        store: storeIscritti,
        paginate: true,
        pageSize: 10,
        reshapeOnPush: true,
      })
    );

    const { listini } = storeToRefs(archivioListiniStore);

    const storeListini = new CustomStore({
      load: () => archivioListiniStore.getListiniValidi(),
      key: "id",
      loadMode: "raw",
    });

    const listiniData = ref(
      new DataSource({
        store: storeListini,
        paginate: true,
        pageSize: 10,
        reshapeOnPush: true,
      })
    );

    onMounted(async () => {
      await anagraficaIscrittiStore.getAllIscritti();
      await archivioListiniStore.getListiniValidi();
      // permessi
      let user = JSON.parse(localStorage.getItem("user"));

      if (user.permissions.includes("Admin")) {
        canCreate.value = true;
        canRead.value = true;
        canUpdate.value = true;
        canDuplicate.value = true;
        canDelete.value = true;
      }
      if (user.permissions.includes("Role")) {
        canCreate.value = true;
        canRead.value = true;
        canUpdate.value = true;
        canDuplicate.value = true;
      }
      if (user.permissions.includes("User")) {
        canRead.value = true;
      }

      connectionStarted.value = true;

      const hubConnection = new HubConnectionBuilder()
        .withUrl(WS_URL + "/archivioAbbonamentiHub", {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        })
        .build();

      hubConnection.start().then(() => {
        let userCookie = JSON.parse(localStorage.getItem("user"));
        hubConnection.on("insert", (tenant, name, data) => {
          if (
            (userCookie.tenant === null && name.toUpperCase() == "DEFAULT") ||
            userCookie.tenant.id === tenant
          ) {
            data = JSON.parse(data);
            store.push([{ type: "insert", key: data.id, data }]);
          }
        });
        hubConnection.on("update", (tenant, name, data) => {
          if (
            (userCookie.tenant === null && name.toUpperCase() == "DEFAULT") ||
            userCookie.tenant.id === tenant
          ) {
            data = JSON.parse(data);
            store.push([{ type: "update", key: data.id, data }]);
          }
        });
        hubConnection.on("remove", (tenant, name, id) => {
          if (
            (userCookie.tenant === null && name.toUpperCase() == "DEFAULT") ||
            userCookie.tenant.id === tenant
          ) {
            store.push([{ type: "remove", key: id }]);
          }
        });
      });
    });

    function createItem() {
      popupTitle.value = t("new") + " " + t("membership");
      actionType.value = "create";
      editedItem.value = JSON.parse(JSON.stringify(defaultItem.value));
      openPopup();
    }
    function readItem(event) {
      popupTitle.value = t("viewT") + " " + t("membership");
      actionType.value = "read";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      index.value = event.row.dataIndex;
      readonly.value = true;
      openPopup();
    }
    function updateItem(event) {
      popupTitle.value = t("update") + " " + t("membership");
      actionType.value = "update";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      index.value = event.row.dataIndex;
      openPopup();
    }
    function duplicateItem(event) {
      popupTitle.value = t("duplicate") + " " + t("membership");
      actionType.value = "duplicate";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      editedItem.value.id = null;
      editedItem.value.nome_iscritto = null;
      //editedItem.value.tipologia_abbonamento_id = null;
      editedItem.value.creato_il = null;
      editedItem.value.aggiornato_il = null;
      editedItem.value.eliminato_il = null;
      editedItem.value.activeValue = true;
      openPopup();
    }
    function deleteItem(event) {
      actionType.value = "delete";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      index.value = event.row.dataIndex;
      openDelete();
    }
    async function deleteItemConfirm() {
      await archivioAbbonamentiStore.deleteAbbonamenti(editedItem.value);
      let item = JSON.parse(JSON.stringify(editedItem.value));
      store.push([{ type: "remove", key: item.id }]);
      showToast("success");
      closeDelete();
    }
    function nomeListinoChanged(e) {
      infoListino.value = e.selectedItem;
      if (e.selectedItem?.id != editedItem.value.tipologia_abbonamento_id && e.selectedItem?.tipologia_calcolo == 'Forfait') {
        let date = new Date(editedItem.value.data_abbonamento);
        editedItem.value.data_scadenza_abbonamento = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() + e.selectedItem.durata,
          12
        );
      }
    }

    async function save() {
      if (formRef.value.instance.validate().isValid) {
        if (index.value > -1) {
          await archivioAbbonamentiStore
            .updateAbbonamenti(editedItem.value)
            .then((data) => {
              let item = JSON.parse(JSON.stringify(data.data));
              store.push([{ type: "update", key: item.id, data: item }]);
              showToast("success");
              closePopup();
            })
            .catch((error) => {
              console.log(error);
              let message = error.response.data.title
                ? error.response.data.title
                : error.response.data.message;
              showToast("error", message);
            });
        } else {
          editedItem.value.title = "them";
          await archivioAbbonamentiStore
            .createAbbonamenti(editedItem.value)
            .then((data) => {
              let item = JSON.parse(JSON.stringify(data.data));
              store.push([{ type: "insert", key: item.id, data: item }]);
              showToast("success");
              closePopup();
            })
            .catch((error) => {
              console.log(error);
              let message = error.response.data.title
                ? error.response.data.title
                : error.response.data.message;
              showToast("error", message);
            });
        }
      }
    }

    function showToast(type, error = null) {
      let message;
      if (type == "success")
        message = t(actionType.value) + " " + t("successful");
      else message = error;
      notify(
        {
          message: message,
          maxWidth: 600,
        },
        type,
        4000
      );
    }

    function refresh() {
      abbonamentiData.value.reload();
    }

    async function openPopup() {
      openingPopupDA.value = true;
      popup.value = true;
      iscrittiData.value.reload();
      listiniData.value.reload();
      await nextTick();
      openingPopupDA.value = false;
    }
    function closePopup() {
      popup.value = false;
      //formRef.value?.instance?.resetValues();
      index.value = -1;
      readonly.value = false;
      //editedItem.value = null;
      actionType.value = "";
    }
    function openDelete() {
      popupDelete.value = true;
    }
    function closeDelete() {
      popupDelete.value = false;
      index.value = -1;
      actionType.value = "";
    }

    function passMatch() {
      return editedItem.value
        ? editedItem.value.password
          ? editedItem.value.password
          : ""
        : "";
    }
    return {
      // VARIABILI

      //users,
      //usersData,
      //roles,
      defaultItem,
      editedItem,
      popup,
      popupDelete,
      popupTitle,
      formData,
      readonly,
      index,
      gridRef,
      formRef,
      connectionStarted,
      actionType,
      openingPopupDA,
      infoListino,
      abbonamenti,
      abbonamentiData,
      iscritti,
      iscrittiData,
      listini,
      listiniData,
      now,

      // PERMESSI
      canCreate,
      canRead,
      canUpdate,
      canDuplicate,
      canDelete,

      // FUNZIONI
      openPopup,
      closePopup,
      openDelete,
      closeDelete,
      passMatch,

      // CRUD
      createItem,
      readItem,
      updateItem,
      duplicateItem,
      deleteItem,
      deleteItemConfirm,
      nomeListinoChanged,
      refresh,
      save,
    };
  },
  components: {
    DxDataGrid,
    DxColumnGrid,
    DxSearchPanelGrid,
    DxHeaderFilterGrid,
    DxFilterRowGrid,
    DxPagingGrid,
    DxEditingGrid,
    DxToolbarGrid,
    DxItemGrid,
    DxButtonGrid,
    //DxLookupGrid,
    DxScrollingGrid,
    DxCheckBox,
    //DxDateBox,

    DxForm,
    DxItemForm,
    DxRequiredRule,
    //DxStringLengthRule,
    // DxCompareRule,

    DxScrollView,

    DxPopup,
    DxToolbarItemPopup,

    DxButton,
  },
};
</script>

<style scoped>
#dataGrid {
  height: 93vh;
}

#delDiv {
  text-align: center;
}

#delTitle {
  font-size: 30px;
  margin: 300px auto;
}

#delBody {
  font-size: 20px;
}

.membership {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 20px;
  margin: 1px;
  border-radius: 5px;
  border: 1px solid #596980;
}

.grid-front {
  font-size: 36px;
}

:deep(.dx-datagrid-header-panel .dx-toolbar) {
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

:deep(.dx-toolbar .dx-toolbar-before) {
  margin-top: -6px;
}

:deep(.dx-header-row) {
  color: #ff5722
}
</style>
