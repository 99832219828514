<template>
  <div id="container">
    <div class="content-block borderingOrange" v-if="connectionStarted">
      <DxDataGrid ref="gridRef" :data-source="usersData" :show-borders="true">
        <DxSearchPanelGrid :visible="true" />
        <DxHeaderFilterGrid :visible="true" />
        <DxFilterRowGrid :visible="true" />
        <DxPagingGrid :enabled="false" />
        <DxScrollingGrid mode="virtual" />
        <DxEditingGrid
          :allow-adding="canRead"
          :allow-updating="canUpdate"
          :allow-deleting="canDelete"
          :allow-filtering="true"
          :use-icons="true"
          refresh-mode="refresh"
          mode="popup"
        >
        </DxEditingGrid>
        <DxColumnGrid
          data-field="firstName"
          :caption="$t('first_name')"
          :allow-header-filtering="false"
        />
        <DxColumnGrid
          data-field="lastName"
          :caption="$t('last_name')"
          :allow-header-filtering="false"
        />
        <DxColumnGrid
          data-field="roles"
          :caption="$t('roles')"
          :allow-header-filtering="false"
        >
          <DxLookupGrid
            :data-source="roles"
            display-expr="roleName"
            value-expr="id"
          />
        </DxColumnGrid>
        <DxColumnGrid
          data-field="email"
          :caption="$t('email')"
          :allow-header-filtering="false"
        />
        <DxColumnGrid type="buttons">
          <DxButtonGrid
            name="read"
            v-if="canRead"
            icon="isblank"
            :hint="$t('viewT')"
            @click="readItem($event)"
          />
          <DxButtonGrid
            name="edit"
            v-if="canUpdate"
            :hint="$t('update')"
            @click="updateItem($event)"
          />
          <DxButtonGrid
            name="duplicate"
            v-if="canDuplicate"
            icon="copy"
            :hint="$t('duplicateT')"
            @click="duplicateItem($event)"
          />
          <DxButtonGrid
            name="delete"
            v-if="canDelete"
            :hint="$t('deleteT')"
            @click="deleteItem($event)"
          />
        </DxColumnGrid>
        <DxToolbarGrid>
          <DxItemGrid location="before" template="front" />
          <DxItemGrid location="after" template="addBtn" />
          <DxItemGrid location="after" template="refreshBtn" />
        </DxToolbarGrid>
        <template #front>
          <span class="grid-front colorOrange">{{ $t("users") }}</span>
        </template>
        <template #addBtn>
          <DxButton
            name="add"
            v-if="canCreate"
            type="contained"
            icon="add"
            :hint="$t('add')"
            @click="createItem()"
          ></DxButton>
        </template>
        <template #refreshBtn>
          <DxButton
            name="refresh"
            type="contained"
            icon="refresh"
            :hint="$t('refresh')"
            @click="refresh()"
          >
          </DxButton>
        </template>
      </DxDataGrid>
      <DxPopup
        v-model:visible="popup"
        :title="popupTitle"
        content-template="content"
        :show-title="true"
        :full-screen="true"
        :drag-enabled="false"
        :hide-on-outiside-click="false"
        :show-close-button="false"
      >
        <template #content>
          <DxScrollView>
            <DxForm
              :form-data="editedItem"
              label-mode="floating"
              class="card"
              ref="formRef"
            >
              <DxItemForm item-type="group" :caption="$t('userInfo')">
                <DxItemForm
                  class="dx-field-value"
                  data-field="firstName"
                  editor-type="dxTextBox"
                  :label="{
                    text: $t('first_name'),
                  }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }"
                >
                  <DxRequiredRule
                    :message="
                      $t('required_error_start') +
                      ' ' +
                      $t('first_name') +
                      ' ' +
                      $t('required_error_end')
                    "
                  />
                </DxItemForm>
                <DxItemForm
                  data-field="lastName"
                  editor-type="dxTextBox"
                  :label="{
                    text: $t('last_name'),
                  }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }"
                >
                  <DxRequiredRule
                    :message="
                      $t('required_error_start') +
                      ' ' +
                      $t('last_name') +
                      ' ' +
                      $t('required_error_end')
                    "
                  />
                </DxItemForm>
                <DxItemForm
                  data-field="email"
                  editor-type="dxTextBox"
                  :label="{
                    text: $t('email'),
                  }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    mode: 'email',
                    readOnly: readonly,
                  }"
                >
                  <DxRequiredRule
                    :message="
                      $t('required_error_start') +
                      ' ' +
                      $t('email') +
                      ' ' +
                      $t('required_error_end')
                    "
                  />
                </DxItemForm>
                <DxItemForm
                  data-field="roles"
                  editor-type="dxTagBox"
                  :label="{
                    text: $t('roles'),
                  }"
                  :editor-options="{
                    dataSource: roles,
                    displayExpr: 'roleName',
                    valueExpr: 'id',
                    placeholder:
                      $t('select_place_holder') + ' ' + $t('role') + '...',
                    searchEnabled: true,
                    readOnly: readonly,
                  }"
                >
                  <DxRequiredRule
                    :message="
                      $t('required_error_start') +
                      ' ' +
                      $t('roles') +
                      ' ' +
                      $t('required_error_end')
                    "
                  />
                </DxItemForm>
                <DxItemForm
                  data-field="isActive"
                  editor-type="dxCheckBox"
                  :label="{
                    text: $t('active'),
                  }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }"
                >
                </DxItemForm>
              </DxItemForm>
              <DxItemForm
                v-if="!readonly"
                item-type="group"
                :caption="$t('password')"
              >
                <DxItemForm
                  data-field="password"
                  editor-type="dxTextBox"
                  :label="{
                    text: $t('password'),
                  }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    mode: 'password',
                    readOnly: readonly,
                  }"
                >
                  <DxRequiredRule
                    :message="
                      $t('required_error_start') +
                      ' ' +
                      $t('password') +
                      ' ' +
                      $t('required_error_end')
                    "
                  />
                  <DxStringLengthRule
                    :ignore-empty-value="actionType == 'update'"
                    :min="6"
                    :message="$t('password_length_error')"
                  />
                </DxItemForm>
                <DxItemForm
                  data-field="confirmPassword"
                  editor-type="dxTextBox"
                  :label="{
                    text: $t('confirm_password'),
                  }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    mode: 'password',
                    readOnly: readonly,
                  }"
                >
                  <DxRequiredRule
                    :message="
                      $t('required_error_start') +
                      ' ' +
                      $t('confirm_password') +
                      ' ' +
                      $t('required_error_end')
                    "
                  />
                  <DxCompareRule
                    :ignore-empty-value="actionType == 'update'"
                    :comparison-target="passMatch"
                    :message="$t('password_match_error')"
                  />
                </DxItemForm>
              </DxItemForm>
            </DxForm>
          </DxScrollView>
        </template>
        <DxToolbarItemPopup
          v-if="!readonly"
          widget="dxButton"
          location="after"
          toolbar="bottom"
          :options="{
            text: $t('save'),
            type: 'default',
            stylingMode: 'outlined',
            onClick: save,
          }"
        />
        <DxToolbarItemPopup
          widget="dxButton"
          location="after"
          toolbar="bottom"
          :options="{
            text: readonly ? $t('close') : $t('cancel'),
            type: 'default',
            stylingMode: 'outlined',
            onClick: closePopup,
          }"
        />
      </DxPopup>
      <DxPopup
        v-model:visible="popupDelete"
        max-width="500px"
        max-height="160px"
        title-template="front"
        content-template="body"
        :drag-enabled="false"
        :hide-on-outiside-click="false"
      >
        <template #front>
          <div id="delDiv">
            <span id="delTitle">
              {{ $t("dialog_delete_title") }}
            </span>
          </div>
        </template>
        <template #body>
          <div id="delDiv">
            <span id="delBody">{{ $t("dialog_delete_body") }}</span>
          </div>
        </template>
        <DxToolbarItemPopup
          widget="dxButton"
          location="center"
          toolbar="bottom"
          :options="{
            text: $t('yes'),
            type: 'default',
            stylingMode: 'contained',
            onClick: deleteItemConfirm,
          }"
        />
        <DxToolbarItemPopup
          widget="dxButton"
          location="center"
          toolbar="bottom"
          :options="{
            text: $t('no'),
            type: 'default',
            stylingMode: 'contained',
            onClick: closeDelete,
          }"
        />
      </DxPopup>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useUsersStore } from "../../stores/UsersStore";
import { useRolesStore } from "../../stores/RolesStore";
import { storeToRefs } from "pinia";

import DataSource from "devextreme/data/data_source";

import CustomStore from "devextreme/data/custom_store";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";

import notify from "devextreme/ui/notify";
import "devextreme-vue/tag-box";

import DxDataGrid, {
  DxColumn as DxColumnGrid,
  DxSearchPanel as DxSearchPanelGrid,
  DxHeaderFilter as DxHeaderFilterGrid,
  DxFilterRow as DxFilterRowGrid,
  DxPaging as DxPagingGrid,
  DxEditing as DxEditingGrid,
  DxButton as DxButtonGrid,
  DxItem as DxItemGrid,
  DxToolbar as DxToolbarGrid,
  DxScrolling as DxScrollingGrid,
  DxLookup as DxLookupGrid,
} from "devextreme-vue/data-grid";
import DxForm, {
  DxItem as DxItemForm,
  DxRequiredRule,
  DxCompareRule,
  DxStringLengthRule,
} from "devextreme-vue/form";
import DxScrollView from "devextreme-vue/scroll-view";
import DxButton from "devextreme-vue/button";
import DxPopup, {
  DxToolbarItem as DxToolbarItemPopup,
} from "devextreme-vue/popup";

import { useI18n } from "vue-i18n";
import { settings } from "@/app-info"

export default {
  setup() {
    const { t } = useI18n();
    const WS_URL = settings.wsUrl;
    const usersStore = useUsersStore();
    const rolesStore = useRolesStore();

    const defaultItem = ref({
      id: "",
      firstName: "",
      user_id: "",
      lastName: "",
      email: "",
      password: "",
      confirm_password: "",
      role: null,
      roles: [],
      isActive: false,
      isDeleted: false,
      isVerified: false,
      tenant: null,
    });
    const editedItem = ref(null);

    const canCreate = ref(false);
    const canRead = ref(false);
    const canUpdate = ref(false);
    const canDuplicate = ref(false);
    const canDelete = ref(false);

    const gridRef = ref(null);
    const formRef = ref(null);
    const popup = ref(false);
    const popupDelete = ref(false);
    const popupTitle = ref("");
    const formData = ref(null);
    const readonly = ref(false);
    const index = ref(-1);
    const connectionStarted = ref(false);
    const actionType = ref("");

    const { users } = storeToRefs(usersStore);

    const store = new CustomStore({
      load: () => usersStore.getUsers(),
      key: "id",
    });

    const usersData = ref(
      new DataSource({
        store: store,
        reshapeOnPush: true,
      })
    );

    const { roles } = storeToRefs(rolesStore);

    onMounted(async () => {
      await rolesStore.getRoles();
      let user = JSON.parse(localStorage.getItem("user"));

      if (user.permissions.includes("Admin")) {
        canCreate.value = true;
        canRead.value = true;
        canUpdate.value = true;
        canDuplicate.value = true;
        canDelete.value = true;
      }
      if (user.permissions.includes("Role")) {
        canCreate.value = true;
        canRead.value = true;
        canUpdate.value = true;
        canDuplicate.value = true;
      }
      if (user.permissions.includes("User")) {
        canRead.value = true;
      }

      connectionStarted.value = true;

      const hubConnection = new HubConnectionBuilder()
        .withUrl(WS_URL + "/accountHub", {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        })
        .build();

      hubConnection.start().then(() => {
        let userCookie = JSON.parse(localStorage.getItem("user"));
        hubConnection.on("insert", (tenant, name, data) => {
          if (
            (userCookie.tenant === null && name.toUpperCase() == "DEFAULT") ||
            userCookie.tenant.id === tenant
          ) {
            data = JSON.parse(data);
            store.push([{ type: "insert", key: data.id, data }]);
          }
        });
        hubConnection.on("update", (tenant, name, data) => {
          if (
            (userCookie.tenant === null && name.toUpperCase() == "DEFAULT") ||
            userCookie.tenant.id === tenant
          ) {
            data = JSON.parse(data);
            store.push([{ type: "update", key: data.id, data }]);
          }
        });
        hubConnection.on("remove", (tenant, name, id) => {
          if (
            (userCookie.tenant === null && name.toUpperCase() == "DEFAULT") ||
            userCookie.tenant.id === tenant
          ) {
            store.push([{ type: "remove", key: id }]);
          }
        });
      });
    });

    function createItem() {
      popupTitle.value = t("create") + " " + t("user");
      actionType.value = "create";
      editedItem.value = JSON.parse(JSON.stringify(defaultItem.value));
      openPopup();
    }
    function readItem(event) {
      popupTitle.value = t("view") + " " + t("user");
      actionType.value = "read";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      index.value = event.row.dataIndex;
      readonly.value = true;
      openPopup();
    }
    function updateItem(event) {
      popupTitle.value = t("update") + " " + t("user");
      actionType.value = "update";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      index.value = event.row.dataIndex;
      openPopup();
    }
    function duplicateItem(event) {
      popupTitle.value = t("duplicate") + " " + t("user");
      actionType.value = "duplicate";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      editedItem.value.id = null;
      openPopup();
    }
    function deleteItem(event) {
      actionType.value = "delete";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      index.value = event.row.dataIndex;
      openDelete();
    }
    async function deleteItemConfirm() {
      await usersStore.deleteUser(editedItem.value);
      let item = JSON.parse(JSON.stringify(editedItem.value));
      store.push([{ type: "remove", key: item.id }]);
      showToast("success");
      closeDelete();
    }

    async function save() {
      if (formRef.value.instance.validate().isValid) {
        if (index.value > -1) {
          await usersStore
            .updateUser(editedItem.value)
            .then((data) => {
              let item = JSON.parse(JSON.stringify(data.data));
              store.push([{ type: "update", key: item.id, data: item }]);
              showToast("success");
              closePopup();
            })
            .catch((error) => {
              console.log(error);
              let message = error.response.data.title
                ? error.response.data.title
                : error.response.data.message;
              showToast("error", message);
            });
        } else {
          editedItem.value.title = "them";
          await usersStore
            .createUser(editedItem.value)
            .then((data) => {
              let item = JSON.parse(JSON.stringify(data.data));
              store.push([{ type: "insert", key: item.id, data: item }]);
              showToast("success");
              closePopup();
            })
            .catch((error) => {
              console.log(error);
              let message = error.response.data.title
                ? error.response.data.title
                : error.response.data.message;
              showToast("error", message);
            });
        }
      }
    }

    function showToast(type, error = null) {
      let message;
      if (type == "success")
        message = t(actionType.value) + " " + t("successful");
      else message = error;
      notify(
        {
          message: message,
          maxWidth: 600,
        },
        type,
        4000
      );
    }

    function refresh() {
      usersData.value.reload();
    }

    function openPopup() {
      popup.value = true;
    }
    function closePopup() {
      popup.value = false;
      formRef.value.instance.resetValues();
      index.value = -1;
      readonly.value = false;
      editedItem.value = null;
      actionType.value = "";
    }
    function openDelete() {
      popupDelete.value = true;
    }
    function closeDelete() {
      popupDelete.value = false;
      index.value = -1;
      actionType.value = "";
    }

    function passMatch() {
      return editedItem.value
        ? editedItem.value.password
          ? editedItem.value.password
          : ""
        : "";
    }

    return {
      // VARIABILI

      users,
      usersData,
      defaultItem,
      editedItem,
      popup,
      popupDelete,
      popupTitle,
      formData,
      readonly,
      index,
      gridRef,
      formRef,
      connectionStarted,
      actionType,
      roles,

      // PERMESSI
      canCreate,
      canRead,
      canUpdate,
      canDuplicate,
      canDelete,

      // FUNZIONI
      openPopup,
      closePopup,
      openDelete,
      closeDelete,
      passMatch,

      // CRUD
      createItem,
      readItem,
      updateItem,
      duplicateItem,
      deleteItem,
      deleteItemConfirm,
      refresh,
      save,
    };
  },
  components: {
    DxDataGrid,
    DxColumnGrid,
    DxSearchPanelGrid,
    DxHeaderFilterGrid,
    DxFilterRowGrid,
    DxPagingGrid,
    DxEditingGrid,
    DxToolbarGrid,
    DxItemGrid,
    DxButtonGrid,
    DxScrollingGrid,
    DxLookupGrid,

    DxForm,
    DxItemForm,
    DxRequiredRule,
    DxStringLengthRule,
    DxCompareRule,

    DxScrollView,

    DxPopup,
    DxToolbarItemPopup,

    DxButton,
  },
};
</script>

<style scoped>
#delDiv {
  text-align: center;
}

#delTitle {
  font-size: 30px;
  margin: 300px auto;
}

#delBody {
  font-size: 20px;
}

.card {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 20px;
  margin: 1px;
  border-radius: 5px;
  border: 1px solid #596980;
}

.grid-front {
  font-size: 36px;
}

:deep(.dx-datagrid-header-panel .dx-toolbar) {
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

:deep(.dx-toolbar .dx-toolbar-before) {
  margin-top: -6px;
}

:deep(.dx-header-row) {
  color:#ff5722
}

</style>
