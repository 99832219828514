<template>
  <div class="row">
    <div class="col">
      <DxGallery
        ref="galleryRef"
        width="60vw"
        :height="300"
        :data-source="galleryData"
        :no-data-text="$t('galleryNoDataText')"
        :loop="false"
        :slideshow-delay="0"
        :show-nav-buttons="true"
        :show-indicator="true"
        item-template="item"
        @selectionChanged="onSelectionChanged($event)"
      >
        <template #item="{ data }">
          <!-- <span>{{data}}</span> -->
          <img :src="'data:image/png;base64,' + data.image" class="img" />
        </template>
      </DxGallery>
      <DxButton
        v-if="!galleryReadonly"
        class="btn"
        name="delete"
        icon="trash"
        :text="$t('deleteT')"
        styling-mode="outlined"
        type="danger"
        :hint="$t('deleteT')"
        @click="deleteItem($event)"
      />
    </div>
    <DxFileUploader
      ref="fileUploaderRef"
      width="20vw"
      :disabled="galleryReadonly"
      :multiple="true"
      upload-mode="useForm"
      :select-button-text="$t('selectBtnText')"
      :label-text="$t('labelText')"
      :ready-to-upload-message="$t('readyToUploadMessage')"
      :uploaded-message="$t('uploadedMessage')"
      :upload-aborted-message="$t('uploadAbortedMessage')"
      :upload-failed-message="$t('uploadFailedMessage')"
      @value-changed="getFileData($event)"
    >
    </DxFileUploader>
    <!-- <DxDataGrid
            id="grid"
            ref="gridRef"
            width="25vw"
            :height="300"
            :data-source="pics"
            :show-column-headers="true"
            :show-column-lines="false"
            :show-row-lines="true"
            :selection="{
                allowSelectAll:true,
                mode: 'multiple',
                selectAllMode: 'allPages',
                showCheckBoxesMode: 'always'
            }"
            @selectionChanged="selectedRowsCheck($event)">
            <DxEditing :allow-deleting="true" :use-icons="true"/>
            <DxColumn data-field="name" caption=""/>
            <DxColumn type="buttons">
                <DxButton name="delete" :hint="$t('deleteT')" @click="deleteItem($event)"/>
            </DxColumn>
        </DxDataGrid> -->
    <DxPopup
      v-model:visible="popupDelete"
      max-width="500px"
      max-height="160px"
      title-template="title"
      content-template="body"
      :drag-enabled="false"
      :hide-on-outiside-click="false"
    >
      <template #title>
        <div id="delDiv">
          <span id="delTitle">
            {{ $t("dialog_delete_title") }}
          </span>
        </div>
      </template>
      <template #body>
        <div id="delDiv">
          <span id="delBody">{{ $t("dialog_delete_body") }}</span>
        </div>
      </template>
      <DxToolbarItem
        widget="dxButton"
        location="center"
        toolbar="bottom"
        :options="{
          text: $t('yes'),
          type: 'default',
          stylingMode: 'contained',
          onClick: deleteItemConfirm,
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        location="center"
        toolbar="bottom"
        :options="{
          text: $t('no'),
          type: 'default',
          stylingMode: 'contained',
          onClick: closeDelete,
        }"
      />
    </DxPopup>
  </div>
</template>

<script>
import { ref } from "vue";
import { useGalleryStore } from "../stores/GalleryStore";
import { storeToRefs } from "pinia";

import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";

import DxGallery from "devextreme-vue/gallery";
import DxFileUploader from "devextreme-vue/file-uploader";
// import DxDataGrid, { DxEditing, DxColumn } from 'devextreme-vue/data-grid';
import DxPopup, { DxToolbarItem } from "devextreme-vue/popup";
import DxButton from "devextreme-vue/button";

import notify from "devextreme/ui/notify";

import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    const galleryStore = useGalleryStore();

    const galleryRef = ref(null);
    const fileUploaderRef = ref(null);
    // const gridRef = ref(null);
    const popupDelete = ref(false);
    const index = ref(0);
    const remove = ref(null);

    const { gallerySrc, galleryReadonly } = storeToRefs(galleryStore);

    const store = new ArrayStore({
      data: gallerySrc.value,
      key: "image",
      onModified: () => {
        galleryData.value.reload();
      },
    });
    const galleryData = ref(
      new DataSource({
        store: store,
        reshapeOnPush: true,
      })
    );

    function getFileData(e) {
      let files = e.value;
      for (let pic of files) {
        if (!pic.type.includes("image/")) {
          showToast(t("wrongImageTypeError"));
          fileUploaderRef.value.instance.reset();
          return;
        }
        const fileReader = new FileReader();
        fileReader.onload = () => {
          let res = fileReader.result;
          let split = res.split(",")[1];
          let found = gallerySrc.value.find((x) => x.image == split);
          if (found != null) {
            showToast(
              t("alreadyUploadedErrorStart") +
                ' "' +
                pic.name +
                '" ' +
                t("alreadyUploadedErrorEnd")
            );
            return;
          } else store.insert({ id: null, image: split });
        };
        fileReader.readAsDataURL(pic);
      }
    }

    function onSelectionChanged(event) {
      index.value = gallerySrc.value.findIndex(
        (x) => x.image == event.addedItems[0].image
      );
      // console.log('event:::', event);
      // console.log('index:::', index.value);
    }

    function deleteItem() {
      remove.value = gallerySrc.value[index.value];
      if (remove.value === undefined) return;
      openDelete();
    }
    async function deleteItemConfirm() {
      store.remove(remove.value.image);
      closeDelete();
    }

    function openDelete() {
      popupDelete.value = true;
    }
    function closeDelete() {
      index.value = 0;
      popupDelete.value = false;
    }

    function showToast(message) {
      notify(
        {
          message: message,
          maxWidth: 600,
        },
        "error",
        4000
      );
    }

    // function selectedRowsCheck(event) {
    //     for (let key of event.currentSelectedRowKeys) {
    //         indices.value.push(key.id);
    //     } for (let key of event.currentDeselectedRowKeys) {
    //         let found = indices.value.findIndex((x) => x == key.id)
    //         indices.value.splice(found, 1);
    //     }
    // }

    return {
      galleryRef,
      // gridRef,
      fileUploaderRef,
      galleryData,
      galleryReadonly,
      popupDelete,

      getFileData,
      // selectedRowsCheck,
      onSelectionChanged,
      deleteItem,
      deleteItemConfirm,
      closeDelete,
    };
  },
  components: {
    DxGallery,
    DxFileUploader,
    // DxDataGrid,
    // DxEditing,
    // DxColumn,
    DxButton,
    DxPopup,
    DxToolbarItem,
  },
};
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: row;
}
.col {
  display: flex;
  flex-direction: column;
}

.dx-gallery {
  border-radius: 5px;
  border: 1px solid #596980;
}

.img {
  width: auto;
  height: auto;
  max-width: 60vw;
  max-height: 300px;
  margin: auto;
  object-fit: contain;
  display: block;
}

.btn {
  width: 15%;
  align-self: center;
  margin-top: 2px;
}

/* #grid {
    border-radius: 2px !important;
    border: 1px solid #596980 !important;
} */

#delDiv {
  text-align: center;
}

#delTitle {
  font-size: 30px;
  margin: 300px auto;
}

#delBody {
  font-size: 20px;
}
</style>
