<template>
  <dx-scroll-view height="100%" width="100%" class="with-footer single-card">
    <div class="head-brand">
      <img
        src="https://raw.githubusercontent.com/QCSNET/simple-internal-developer-utilities/main/LOGHI-README-GitHub/LogoGimmy.svg"
        alt="Logo Presenze"
        width="80"
      />
    </div>
    <div class="dx-card content">
      <div class="header">
        <div class="title">{{ title }}</div>
        <div class="description">{{ description }}</div>
      </div>
      <slot />
    </div>
    <div class="brand">
      <h4>powered by QCSNET s.r.l.</h4>
      <img
        src="https://raw.githubusercontent.com/QCSNET/simple-internal-developer-utilities/main/LOGHI-README-GitHub/logo_nerd_nero.png"
        alt="Logo QCS"
        width="50"
      />
    </div>
  </dx-scroll-view>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";

import { useRoute } from "vue-router";
import { watch, ref } from "vue";

export default {
  components: {
    DxScrollView,
  },
  setup() {
    const route = useRoute();

    const title = ref(route.meta.title);
    const description = ref("");

    watch(
      () => route.path,
      () => {
        title.value = route.meta.title;
        description.value = route.meta.description;
      }
    );
    return {
      title,
      description,
    };
  },
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.single-card {
  width: 100%;
  height: 100%;

  .dx-card {
    width: 330px;
    margin: 10px auto 20px;
    padding: 40px;
    flex-grow: 0;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 30px;

      .title {
        color: $base-text-color;
        line-height: 28px;
        font-weight: 500;
        font-size: 22px;
      }

      .description {
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 18px;
      }
    }
  }

  .brand {
    margin-top: 0px;
    margin-bottom: auto;
    text-align: center;
    display: inline-block;
  }

  .head-brand {
    margin-top: auto;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: inline-block;
    background-color: white;
  }

  h4 {
    margin-top: 0px;
  }
}
</style>
