import axiosInstance from "../axiosSetup/api";

const API_URL = "/archivioListini/";

class ListiniService {
  getListiniValidi() {
    return axiosInstance.get(API_URL + "get-listini-validi/");
  }
  getTipologie() {
    return axiosInstance.get(API_URL + "get-tipologie/");
  }
  getAllListini() {
    return axiosInstance.get(API_URL);
  }
  createListini(item) {
    return axiosInstance.post(API_URL, item);
  }
  updateListini(item) {
    return axiosInstance.put(API_URL + item.id, item);
  }
  deleteListini(item) {
    return axiosInstance.delete(API_URL + item.id);
  }
}

export default new ListiniService();
