import { defineStore } from "pinia";
import CertificatiService from "@/services/certificatiServices/CertificatiService";

export const useCertificatiMediciStore = defineStore(
  "ArchivioCertificatiMediciStore",
  {
    state: () => ({
      certificati: [],
    }),

    actions: {
      getAllCertificati() {
        return CertificatiService.getAllCertificati().then(
          (certificati) => {
            //console.log("certificati----", certificati);
            this.certificati = certificati.data;
            return Promise.resolve({
              data: this.certificati,
              totalCount: this.certificati.length,
            });
          },
          (error) => {
            return Promise.reject(error);
          }
        );
      },
      createCertificati(item) {
        return CertificatiService.createCertificati(item).then(
          (create) => {
            return Promise.resolve(create);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
      },
      updateCertificati(item) {
        return CertificatiService.updateCertificati(item).then(
          (update) => {
            return Promise.resolve(update);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
      },
      deleteCertificati(item) {
        return CertificatiService.deleteCertificati(item).then(
          (del) => {
            return Promise.resolve(del);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
      },
    },
  }
);
