<template>
  <div id="container">
    <div class="content-block borderingOrange">
      <DxDataGrid id="dataGrid" ref="gridRef" :data-source="certificatiData" :show-borders="true">
        <DxSearchPanelGrid :visible="true" />
        <DxHeaderFilterGrid :visible="true" />
        <DxFilterRowGrid :visible="true" />
        <DxPagingGrid :enabled="false" />
        <DxScrollingGrid mode="virtual" />
        <DxEditingGrid :allow-adding="canRead" :allow-updating="canUpdate" :allow-deleting="canDelete"
          :allow-filtering="true" :use-icons="true" refresh-mode="refresh" mode="popup">
        </DxEditingGrid>
        <DxColumnGrid data-type="string" data-field="nome_iscritto" :caption="$t('subscribed_name')"
          :allow-header-filtering="false" :visible="true" />

        <DxColumnGrid data-field="data_certificato" :caption="$t('medical_certificate_date')" data-type="date"
          format="dd/MM/yyyy" :allow-header-filtering="false" :visible="true" />
        <DxColumnGrid data-field="data_scadenza_certificato" :caption="$t('medical_certificate_expiration_date')"
          data-type="date" format="dd/MM/yyyy" :allow-header-filtering="false" :visible="true" />

        <DxColumnGrid data-field="creato_il" :caption="$t('created_on')" data-type="date" format="dd/MM/yyyy"
          :allow-header-filtering="false" :visible="true" />

        <DxColumnGrid cell-template="disableCell" data-field="activeValue" :caption="$t('status')" data-type="boolean"
          trueText="Attivo" falseText="Disabilitato" :allow-header-filtering="false" :visible="true" :filter-value="true">
        </DxColumnGrid>
        <template #disableCell="{ data }">
          <DxCheckBox :value="data.value" :readOnly="true" />
        </template>
        <DxColumnGrid type="buttons">
          <DxButtonGrid name="read" v-if="canRead" icon="isblank" :hint="$t('viewT')" @click="readItem($event)" />
          <DxButtonGrid name="edit" v-if="canUpdate" :hint="$t('update')" @click="updateItem($event)" />
          <DxButtonGrid name="duplicate" v-if="canDuplicate" icon="copy" :hint="$t('duplicateT')"
            @click="duplicateItem($event)" />
          <DxButtonGrid name="delete" v-if="canDelete" :hint="$t('deleteT')" @click="deleteItem($event)" />
        </DxColumnGrid>
        <DxToolbarGrid>
          <DxItemGrid location="before" template="front" />
          <DxItemGrid location="after" template="addBtn" />
          <DxItemGrid location="after" template="refreshBtn" />
        </DxToolbarGrid>
        <template #front>
          <span class="grid-front colorOrange">{{ $t("medical_certificate_store") }}</span>
        </template>
        <template #addBtn>
          <DxButton name="add" v-if="canCreate" type="contained" icon="add" :hint="$t('add')" @click="createItem()">
          </DxButton>
        </template>
        <template #refreshBtn>
          <DxButton name="refresh" type="contained" icon="refresh" :hint="$t('refresh')" @click="refresh()">
          </DxButton>
        </template>
      </DxDataGrid>
      <DxPopup v-model:visible="popup" :title="popupTitle" content-template="content" :show-title="true"
        :full-screen="true" :drag-enabled="false" :hide-on-outiside-click="false" :show-close-button="false">
        <template #content>
          <DxScrollView>
            <DxForm :form-data="editedItem" label-mode="floating" class="certificate" ref="formRef" :col-count="2">
              <DxItemForm item-type="group" :caption="$t('medical_certificate_data')">
                <DxItemForm v-if="actionType === 'update' || actionType === 'read'" data-field="nome_iscritto"
                  editor-type="dxTextBox" :label="{ text: $t('subscribed_name'), }" :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: true,
                  }" />

                <DxItemForm v-else data-field="user_id" editor-type="dxSelectBox"
                  :label="{ text: $t('subscribed_name'), }" :editor-options="{
                    dataSource: iscrittiData,
                    displayExpr: 'nome_intero',
                    valueExpr: 'id',
                    placeholder: $t('select_place_holder') + ' ' + $t('subscribed_name') + '...',
                    searchEnabled: true,
                    readOnly: readonly,
                  }">
                  <DxRequiredRule
                    :message="$t('required_error_start') + ' ' + $t('subscribeds') + ' ' + $t('required_error_end')" />
                </DxItemForm>

                <DxItemForm data-field="data_certificato" editor-type="dxDateBox"
                  :label="{ text: $t('medical_certificate_date'), }" :editor-options="data_certificato_editor">
                  <DxRequiredRule
                    :message="$t('required_error_start') + ' ' + $t('expiration_date') + ' ' + $t('required_error_end')" />
                </DxItemForm>

                <DxItemForm data-field="data_scadenza_certificato" editor-type="dxDateBox"
                  :label="{ text: $t('medical_certificate_expiration_date'), }" :editor-options="{
                    showClearButton: 'true',
                    type: 'datetime',
                    placeholder: 'please, insert a valid date',
                    stylingMode: 'outlined',
                    readOnly: readonly,
                    maskBehavior: 'true',
                    displayFormat: 'dd/MM/yyyy'
                  }">
                  <DxRequiredRule
                    :message="$t('required_error_start') + ' ' + $t('expiration_date') + ' ' + $t('required_error_end')" />
                </DxItemForm>
              </DxItemForm>
              <DxItemForm item-type="group" :caption="$t('further_info')">
                <DxItemForm data-field="creato_il" editor-type="dxDateBox" data-type="datetime"
                  :label="{ text: $t('created_on'), }" :editor-options="{
                    readOnly: true,
                    maskBehavior: 'true',
                    displayFormat: 'dd/MM/yyyy        HH:mm:ss',
                  }">
                </DxItemForm>
                <DxItemForm data-field="aggiornato_il" editor-type="dxDateBox" :label="{ text: $t('update_on'), }"
                  :editor-options="{
                    readOnly: true,
                    stylingMode: 'outlined',
                    displayFormat: 'dd/MM/yyyy        HH:mm:ss',
                  }">
                </DxItemForm>

                <DxItemForm data-field="eliminato_il" editor-type="dxDateBox" :label="{ text: $t('deleted_on'), }"
                  :editor-options="{
                    readOnly: true,
                    maskBehavior: 'true',
                    displayFormat: 'dd/MM/yyyy        HH:mm:ss',
                  }">
                </DxItemForm>
                <DxItemForm data-field="activeValue" editor-type="dxCheckBox" :label="{ text: $t('active'), }"
                  :editor-options="{
                    stylingMode: 'outlined',
                    readOnly: readonly,
                  }">
                </DxItemForm>
              </DxItemForm>

            </DxForm>
          </DxScrollView>
        </template>
        <DxToolbarItemPopup v-if="!readonly" widget="dxButton" location="after" toolbar="bottom" :options="{
          text: $t('save'),
          type: 'default',
          stylingMode: 'outlined',
          onClick: save,
        }" />
        <DxToolbarItemPopup widget="dxButton" location="after" toolbar="bottom" :options="{
          text: readonly ? $t('close') : $t('cancel'),
          type: 'default',
          stylingMode: 'outlined',
          onClick: closePopup,
        }" />
      </DxPopup>
      <DxPopup v-model:visible="popupDelete" max-width="500px" max-height="160px" title-template="front"
        content-template="body" :drag-enabled="false" :hide-on-outiside-click="false">
        <template #front>
          <div id="delDiv">
            <span id="delTitle">
              {{ $t("dialog_delete_title") }}
            </span>
          </div>
        </template>
        <template #body>
          <div id="delDiv">
            <span id="delBody">{{ $t("dialog_delete_body") }}</span>
          </div>
        </template>
        <DxToolbarItemPopup widget="dxButton" location="center" toolbar="bottom" :options="{
          text: $t('yes'),
          type: 'default',
          stylingMode: 'contained',
          onClick: deleteItemConfirm,
        }" />
        <DxToolbarItemPopup widget="dxButton" location="center" toolbar="bottom" :options="{
          text: $t('no'),
          type: 'default',
          stylingMode: 'contained',
          onClick: closeDelete,
        }" />
      </DxPopup>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";

import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import notify from "devextreme/ui/notify";
import { useGalleryStore } from "../../stores/GalleryStore";

import "devextreme-vue/tag-box";

import DxDataGrid, {
  DxColumn as DxColumnGrid,
  DxSearchPanel as DxSearchPanelGrid,
  DxHeaderFilter as DxHeaderFilterGrid,
  DxFilterRow as DxFilterRowGrid,
  DxPaging as DxPagingGrid,
  DxEditing as DxEditingGrid,
  DxButton as DxButtonGrid,
  DxItem as DxItemGrid,
  DxToolbar as DxToolbarGrid,
  //DxLookup as DxLookupGrid,
  DxScrolling as DxScrollingGrid,
} from "devextreme-vue/data-grid";

import { DxCheckBox } from "devextreme-vue/check-box";
//import DxDateBox from 'devextreme-vue/date-box';

import DxForm, {
  DxItem as DxItemForm,
  DxRequiredRule,
  // DxCompareRule,
  //DxStringLengthRule,
} from "devextreme-vue/form";
import DxScrollView from "devextreme-vue/scroll-view";
import DxButton from "devextreme-vue/button";
import DxPopup, {
  DxToolbarItem as DxToolbarItemPopup,
} from "devextreme-vue/popup";

import { useI18n } from "vue-i18n";
import { useCertificatiMediciStore } from "@/stores/ArchivioCertificatiMediciStore";
import { useIscrittisStore } from "@/stores/AnagraficaIscrittiStore";

import { settings } from "@/app-info";

export default {
  setup() {
    const { t } = useI18n();

    const WS_URL = settings.wsUrl;
    const archivioCertificatiMediciStore = useCertificatiMediciStore();
    const anagraficaIscrittiStore = useIscrittisStore();

    const now = new Date();

    const certificato = now;

    //const scadenza = new Date(
    //  certificato.getFullYear(),
    //  certificato.getMonth() + 1,
    //  certificato.getDate()
    //);

    const defaultItem = ref({
      id: "",
      tenant_id: "",
      user_id: "",
      data_certificato: certificato,
      data_scadenza_certificato: new Date(),
      nome_iscritto: "",
      numero_certificato: "",
      note: "",
      creato_il: "",
      aggiornato_il: "",
      eliminato_il: "",
      activeValue: true,
      tenant: null,
    });
    const editedItem = ref(null);
    const canCreate = ref(false);
    const canRead = ref(false);
    const canUpdate = ref(false);
    const canDuplicate = ref(false);
    const canDelete = ref(false);

    const gridRef = ref(null);
    const formRef = ref(null);
    const popup = ref(false);
    const popupDelete = ref(false);
    const popupTitle = ref("");
    const formData = ref(null);
    const readonly = ref(false);
    const index = ref(-1);
    const connectionStarted = ref(false);
    const actionType = ref("");
    let openingPopupDA = false;

    const galleryItem = ref([]);
    const galleryStore = useGalleryStore();

    const { gallerySrc, galleryReadonly } = storeToRefs(galleryStore);

    const { certificati } = storeToRefs(archivioCertificatiMediciStore);

    const store = new CustomStore({
      load: () => archivioCertificatiMediciStore.getAllCertificati(),
      key: "id",
    });

    const certificatiData = ref(
      new DataSource({
        store: store,
        reshapeOnPush: true,
      })
    );

    const data_certificato_editor = ref({
      showClearButton: 'true',
      type: 'datetime',
      placeholder: 'please, insert a valid date',
      stylingMode: 'outlined',
      readOnly: readonly,
      maskBehavior: 'true',
      displayFormat: 'dd/MM/yyyy',
      onValueChanged: (e) => dataCertificatoChanged(e),
    })

    const { iscritti } = storeToRefs(anagraficaIscrittiStore);

    const storeIscritti = new CustomStore({
      load: () => anagraficaIscrittiStore.getIscrittiSenzaCertificatoMedico(),
      key: "id",
      loadMode: "raw",
    });

    const iscrittiData = ref(
      new DataSource({
        store: storeIscritti,
        paginate: true,
        pageSize: 10,
        reshapeOnPush: true,
      })
    );

    onMounted(async () => {
      await anagraficaIscrittiStore.getAllIscritti();
      // permessi
      let user = JSON.parse(localStorage.getItem("user"));

      if (user.permissions.includes("Admin")) {
        canCreate.value = true;
        canRead.value = true;
        canUpdate.value = true;
        canDuplicate.value = true;
        canDelete.value = true;
      }
      if (user.permissions.includes("Role")) {
        canCreate.value = true;
        canRead.value = true;
        canUpdate.value = true;
        canDuplicate.value = true;
      }
      if (user.permissions.includes("User")) {
        canRead.value = true;
      }

      connectionStarted.value = true;

      const hubConnection = new HubConnectionBuilder()
        .withUrl(WS_URL + "/archivioCertificatiMediciHub", {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        })
        .build();

      hubConnection.start().then(() => {
        let userCookie = JSON.parse(localStorage.getItem("user"));
        hubConnection.on("insert", (tenant, name, data) => {
          if (
            (userCookie.tenant === null && name.toUpperCase() == "DEFAULT") ||
            userCookie.tenant.id === tenant
          ) {
            data = JSON.parse(data);
            store.push([{ type: "insert", key: data.id, data }]);
          }
        });
        hubConnection.on("update", (tenant, name, data) => {
          if (
            (userCookie.tenant === null && name.toUpperCase() == "DEFAULT") ||
            userCookie.tenant.id === tenant
          ) {
            data = JSON.parse(data);
            store.push([{ type: "update", key: data.id, data }]);
          }
        });
        hubConnection.on("remove", (tenant, name, id) => {
          if (
            (userCookie.tenant === null && name.toUpperCase() == "DEFAULT") ||
            userCookie.tenant.id === tenant
          ) {
            store.push([{ type: "remove", key: id }]);
          }
        });
      });
    });

    function dataCertificatoChanged(e) {
      if (openingPopupDA) return;
      const date = new Date(e.value)
      const localDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        12
      );
      editedItem.value.data_certificato = new Date(localDate);
      localDate.setFullYear(localDate.getFullYear() + 1)
      editedItem.value.data_scadenza_certificato = new Date(localDate)
    }

    function createItem() {
      openingPopupDA = true
      popupTitle.value = t("new") + " " + t("certificate");
      actionType.value = "create";
      editedItem.value = JSON.parse(JSON.stringify(defaultItem.value));
      openPopup();
    }
    async function readItem(event) {
      openingPopupDA = true
      popupTitle.value = t("viewT") + " " + t("certificate");
      actionType.value = "read";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      await galleryStore.getGallery(
        "archivio_certificati_medici",
        editedItem.value.id
      );
      index.value = event.row.dataIndex;
      readonly.value = true;
      openPopup();
    }
    async function updateItem(event) {
      openingPopupDA = true
      popupTitle.value = t("update") + " " + t("certificate");
      actionType.value = "update";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      await galleryStore.getGallery(
        "archivio_certificati_medici",
        editedItem.value.id
      );
      index.value = event.row.dataIndex;
      openPopup();
    }
    async function duplicateItem(event) {
      openingPopupDA = true
      popupTitle.value = t("duplicate") + " " + t("certificate");
      actionType.value = "duplicate";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      await galleryStore.getGallery(
        "archivio_certificati_medici",
        editedItem.value.id
      );
      editedItem.value.id = null;
      editedItem.value.numero_certificato = null;
      editedItem.value.creato_il = null;
      editedItem.value.aggiornato_il = null;
      editedItem.value.eliminato_il = null;
      editedItem.value.activeValue = null;
      openPopup();
    }
    function deleteItem(event) {
      actionType.value = "delete";
      editedItem.value = JSON.parse(JSON.stringify(event.row.data));
      index.value = event.row.dataIndex;
      openDelete();
    }
    async function deleteItemConfirm() {
      await archivioCertificatiMediciStore.deleteCertificati(editedItem.value);
      let item = JSON.parse(JSON.stringify(editedItem.value));
      store.push([{ type: "remove", key: item.id }]);
      showToast("success");
      closeDelete();
    }

    async function save() {
      if (formRef.value.instance.validate().isValid) {
        if (index.value > -1) {
          await archivioCertificatiMediciStore
            .updateCertificati(editedItem.value)
            .then(async (data) => {
              for (let item of gallerySrc.value) {
                galleryItem.value.push({
                  Id: item.id,
                  Image: item.image,
                  TableName: "archivio_certificati_medici",
                  TableId: data.data.id,
                });
              }
              await galleryStore
                .updateGallery(editedItem.value.id, galleryItem.value)
                .then(() => {
                  let item = JSON.parse(JSON.stringify(data.data));
                  store.push([{ type: "update", key: item.id, data: item }]);
                  showToast("success");
                  closePopup();
                })
                .catch((error) => {
                  console.log(error);
                  let message = error.response.data.title
                    ? error.response.data.title
                    : error.response.data.message;
                  showToast("error", message);
                });
            })
            .catch((error) => {
              console.log(error);
              let message = error.response.data.title
                ? error.response.data.title
                : error.response.data.message;
              showToast("error", message);
            });
        } else {
          editedItem.value.title = "them";
          await archivioCertificatiMediciStore
            .createCertificati(editedItem.value)
            .then(async (data) => {
              for (let item of gallerySrc.value) {
                galleryItem.value.push({
                  Id: item.id,
                  Image: item.image,
                  TableName: "archivio_certificati_medici",
                  TableId: data.data.id,
                });
              }
              await galleryStore
                .createGallery(galleryItem.value)
                .then(() => {
                  let item = JSON.parse(JSON.stringify(data.data));
                  store.push([{ type: "insert", key: item.id, data: item }]);
                  showToast("success");
                  closePopup();
                })
                .catch((error) => {
                  console.log(error);
                  let message = error.response.data.title
                    ? error.response.data.title
                    : error.response.data.message;
                  showToast("error", message);
                });
            })
            .catch((error) => {
              console.log(error);
              let message = error.response.data.title
                ? error.response.data.title
                : error.response.data.message;
              showToast("error", message);
            });
        }
      }
    }

    function showToast(type, error = null) {
      let message;
      if (type == "success")
        message = t(actionType.value) + " " + t("successful");
      else message = error;
      notify(
        {
          message: message,
          maxWidth: 600,
        },
        type,
        4000
      );
    }

    function refresh() {
      certificatiData.value.reload();
    }

    async function openPopup() {
      popup.value = true;
      iscrittiData.value.reload();
      galleryReadonly.value = readonly;
      openingPopupDA = false;
    }

    function closePopup() {
      popup.value = false;
      //formRef.value.instance.resetValues();
      index.value = -1;
      readonly.value = false;
      galleryReadonly.value = false;
      //editedItem.value = null;
      actionType.value = "";
    }
    function openDelete() {
      popupDelete.value = true;
    }
    function closeDelete() {
      popupDelete.value = false;
      index.value = -1;
      actionType.value = "";
    }

    function passMatch() {
      return editedItem.value
        ? editedItem.value.password
          ? editedItem.value.password
          : ""
        : "";
    }
    return {
      // VARIABILI

      //users,
      //usersData,
      //roles,
      data_certificato_editor,
      defaultItem,
      editedItem,
      popup,
      popupDelete,
      popupTitle,
      formData,
      readonly,
      index,
      gridRef,
      formRef,
      connectionStarted,
      actionType,
      certificati,
      certificatiData,
      iscritti,
      iscrittiData,
      now,
      gallerySrc,
      galleryReadonly,

      // PERMESSI
      canCreate,
      canRead,
      canUpdate,
      canDuplicate,
      canDelete,

      // FUNZIONI
      openPopup,
      closePopup,
      openDelete,
      closeDelete,
      passMatch,

      // CRUD
      createItem,
      readItem,
      updateItem,
      duplicateItem,
      deleteItem,
      deleteItemConfirm,
      refresh,
      save,
    };
  },
  components: {
    //
    //
    DxDataGrid,
    DxColumnGrid,
    DxSearchPanelGrid,
    DxHeaderFilterGrid,
    DxFilterRowGrid,
    DxPagingGrid,
    DxEditingGrid,
    DxToolbarGrid,
    DxItemGrid,
    DxButtonGrid,
    //DxLookupGrid,
    DxScrollingGrid,
    DxCheckBox,
    //DxDateBox,

    DxForm,
    DxItemForm,
    DxRequiredRule,
    //DxStringLengthRule,
    // DxCompareRule,

    DxScrollView,

    DxPopup,
    DxToolbarItemPopup,

    DxButton,
  },
};
</script>

<style scoped>
#dataGrid {
  height: 93vh;
}

#delDiv {
  text-align: center;
}

#delTitle {
  font-size: 30px;
  margin: 300px auto;
}

#delBody {
  font-size: 20px;
}

.certificate {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 20px;
  margin: 1px;
  border-radius: 5px;
  border: 1px solid #596980;
}

.grid-front {
  font-size: 36px;
}

:deep(.dx-datagrid-header-panel .dx-toolbar) {
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

:deep(.dx-toolbar .dx-toolbar-before) {
  margin-top: -6px;
}

:deep(.dx-header-row) {
  color: #ff5722
}
</style>
